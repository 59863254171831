.b2b_container{
    background-color: var( --white);
}

.b2b_wrapper img{
   padding: 10px 20px;
    object-fit: contain;
    transition:0.5s all ease-in-out;
  }

  
.b2b_wrapper:hover img{
  transform: scale(1.3);
  cursor: pointer;
 }

  .b2b_card{
    border:1px solid #E4E7EC;
    border-radius: 12px;
    padding:20px 20px;
  }


@media only screen and (max-width: 600px) {
  .b2b_wrapper img {
   width:100%;
   padding:1px;
    object-fit: cover;
}
     
 }