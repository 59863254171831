.journy_wrapper{
    background-image:url("../../Assets/image/Journey/b.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    border-radius: 20px;
}

.journey_header{
    font-family: 'RedHatDisplay-SemiBold';
font-style: normal;
font-weight: 600;
font-size: 24px;
line-height: 36px;


display: flex;
align-items: center;
color: #101828;
}

.arrowIcon{
    width: 30px;
    
}

.card__video{
    max-width: 100%;
    height: auto;
}

.arrowIcon_outer{
    border: 5px solid #FFFFFF;
    border-radius: 50%;
    padding:16px 18px;
    
}

.journey_wrapper p{
    font-family: 'Cabin-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* or 150% */
    
    
    /* Gray/600 */
    
    color: #475467;
}

.joury_container{
    background: #F9FAFB;
}

@media only screen and (max-width: 600px) {
    .hero_img{
     margin-top: 25px;
    }
    .journey_padding{
        margin-top:10px;
    }

    .journy_wrapper{
        height: 50vh;
    }
     
 }