.story_head {
  font-family: "RedHatDisplay-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #84cc0a;
}

.story_img {
  width: 100%;
  height: 456px;
  /* margin-left: 34px; */
  margin-bottom: 30px;
}

.storyCard_section_Container {
  position: relative;
}

.story_head:before {
  right: 95%;
}

.story_head:after {
  left: 20%;
}

.story_Card {
  background: #d9efb3;
  box-shadow: -100px 100px 150px rgba(52, 64, 84, 0.12);
  border-radius: 0;
  padding: 2rem;
  padding-bottom: 0;
}

.story_header {
  margin-left: 30px;
}

.story_head:before {
  content: "";
  display: block;
  position: absolute;
  width: 555px;
  border-bottom: 1px solid #d9efb3;
  height: 5px;
  top: 50%;
  margin-top: -4px;
  margin-left: 173px;
}
.story_head:after {
  content: "";
  display: block;
  position: absolute;
  width: 565px;
  border-bottom: 1px solid #d9efb3;
  height: 5px;
  top: 50%;
  margin-top: -4px;
  margin-left: 150px;
}

.icon_arrows {
  width: 50px;
  cursor: pointer;
}
.silder-card {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  background: rgba(250, 255, 241, 1);
  box-shadow: none;
  border: none;
  height: 300px;
  justify-content: center;
}
.silder-card > p > span {
  color: #84cc0a;
  opacity: 0px;
  font-family: "RedHatDisplay-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 22px;
}
.silder-card > p {
  font-family: "RedHatDisplay-Regular";
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  font-size: 22px;
  text-align: center;
}

@media only screen and (max-width: 600px) {
  .story_img {
    width: 100%;
    height: auto;
    margin-left: 5px;
    margin-bottom: 10px;
  }

  .story_Card p {
    margin-left: 5px;
  }
}


