.greenBanner_bg{
    display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
padding: 16px 112px;
background: #6AA308;
border-radius: 4px;

}

.greenBanner_head{
font-family: 'Cabin-Regular';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 28px;
text-align: center;
color: #FFFFFF;
}


.rentGreenBtn{
    padding: 10px 20px;
}

.switch_title{
    font-family: "Cabin-Bold";
}

@media only screen and (max-width: 600px) {
    .greenBanner_bg {

        padding: 16px 2px;
        
    }

    .greenBanner_head {
        font-family: 'Cabin-Regular';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        text-align: center;
        color: #FFFFFF;
    }

    .greenBanner_head{
        padding: 2px 10px;
    }
     
 }