.hero-img{
    width: 110px;
    height: 90px;
    margin-left: 23rem;
    margin-top: 12px;

}

.css-1fzpoyk{
    /* opacity: inherit!important; */
    opacity: 1.0!important;;

}

/* .club_Wrap{
    height: 70vh;
    border:1px solid green;
} */

/* .card-carousel {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    overflow: hidden;
    perspective: 1000px;
  } */
  
  .active-slide {
    transform: scale(1.2);
    z-index: 1;
    cursor: pointer;
    transition: transform 0.5s;
  }

  /* .club_card{
    height: 400px;
    width: 550px
  } */

.club-bg{
    position: relative;
    display: flex;
  
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex-shrink: 0;
    border-radius: 24px;
    width:500px;
    height: 380px;
   background: var(--black-dark, #050504);
}
.check-img{
    width: 28px;
    height: 28px;
    margin-bottom: 10px;
}
.card-para{
    color: var(--black-light, #E6E6E6);
    font-family: "Cabin-Regular";
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;

}
.card-head{
    color: var(--luxe-normal, #D6AB33);
    font-family: "RedHatDisplay-SemiBold";
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 700;
    line-height: 36px; /* 150% */
}


.slide{
    color: #ffff;
    transform: scale(.9);
    transition: .5s;
    margin-top: 3rem; 
    z-index: 1;
}

.slide-active{
    opacity: 1;
    transform: scale(1.1);
    margin-top: 50px;
    z-index: 2;
    
  
    
  
}
.slick-center  .slide {
    margin-right: -90px; /* Adjust this value to control the overlap */
  }

.active_Card{
    border: 10px solid #2e2e2e;
}

.card_height{
    background-color: #2e2e2e;
    color: #ffff;
    height: 350px;
}


@media only screen and (max-width: 600px) {
    .card-head {
       
        font-size: 1rem;
        line-height: 20px;
    }

    .hero-img {
       
        margin-left: 5rem;
       
    }

    
element.style {
}
.card-para {
    color: var(--black-light, #E6E6E6);
    font-family: "Cabin-Regular";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
}


.club-bg{
   
    width:300px;
    height: 350px;
   background: var(--black-dark, #050504);
}
}

  
