.acces_title{
 font-family: 'Cabin-Medium';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;
display: flex;
align-items: center;
color: #6AA308;
}

.access_header{
font-family: 'RedHatDisplay-Bold';
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 36px;
color: #101828;
}

.access_para{
font-family: 'Cabin-Regular';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;
}

.access_para2{
    font-family: 'Cabin-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--lightactive);
    }

.number_list{
    width: 40px;
    height: 26px;
    background: #77B809;
    border-radius: 999px;
    color: var(--white);
    text-align: center;
}

.number_list2{
    width: 40px;
    height: 26px;
    background: var(--lightactive);
    border-radius: 999px;
    color: var(--white);
    text-align: center;
}

.list_head{
font-family: 'RedHatDisplay-SemiBold';
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 26px;
display: flex;
align-items: center;
color: #84CC0A;
}

.list_head2{
    font-family: 'RedHatDisplay-SemiBold';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    display: flex;
    align-items: center;
    color: var(--lightactive);
    }
   .wrapper_Access{
    position: relative;
   }

    .wrapper_Access li::before{
       content: url('../../../Assets/image/Assecbility/Line.png');
       position: absolute;
       top: 36px;
       left: 12px;
      
    }

                
 @media only screen and (max-width: 600px) {
    .acces_title{
        margin-top: 10px;
    }
    .number_list2 {
        width: 75px;
      
    }
    .number_list {
        width: 75px;
      
    }
 }

