.contactBanner_bg{
    background-color: var(--blackdark);
}

.contactBanner_head{
font-family: 'RedHatDisplay-Bold';
font-style: normal;
font-weight: 700;
font-size: 48px;
line-height: 56px;
color: #84CC0A;
}

.contact_Title{
    color: #E4E7EC;
}

.contact_para{
    font-size: 20px;
}


@media only screen and (max-width: 600px) {
    .contactBanner_head {
       
        font-size: 30px;
        line-height: 41px;
       
    }

    .support_Title {
       
        font-size: 26px;
       
    }

    .contactBanner_bg{
      padding-top: 30px;
    }
 }