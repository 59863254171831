.BlogBanner_ContainerMain {
  background: #fafff1;
}
.BlogBanner_Img {
  width: 49%;
  height: 440px;
}
.BlogBanner_Img img {
  object-fit: cover;
}
.BlogBanner_Info {
  width: 49%;
  gap: 40px;
}
.BlogBanner_InfoTitle p {
  font-family: "RedHatDisplay-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  color: #101828;
}

.BlogBanner_TickIconBox {
  width: 25px;
  height: 25px;
  background: #edf7da;
  border-radius: 14px;
  padding: 6px;
}
.BlogBanner_TickDes {
  font-family: "Cabin-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #667085;
}

.BlogBanner_Button {
  width: 118px;
  height: 48px;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;

  font-family: "Cabin-SemiBold";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}
.BlogBanner_Button1 {
  background: #ffffff;
  border: 1px solid #d0d5dd;
  color: #344054;
}
.BlogBanner_Button2 {
  background: #84cc0a;
  border: 1px solid #84cc0a;
  color: #ffffff;
}
@media screen and (max-width: 600px) {
  .BlogBanner_Img,
  .BlogBanner_Info {
    width: 100%;
  }
}


