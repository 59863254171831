.MostPopularBlogCardContainer {
  padding: 10px;
  background: #f9fafb;
  box-shadow: 0px 20px 25px -5px rgba(16, 24, 40, 0.1),
    0px 8px 10px -6px rgba(16, 24, 40, 0.1);
  border-radius: 4px;
}
.MostPopularBlogCardImgBox {
  width: 90px;
  height: 90px;
 border: none;
}
.MostPopularBlogCardImgBox img{
  object-fit: cover;
}
.MostPopularBlogCardInfoBox {
  width: 260px;
}
.MostPopularBlogCardTitle {
  font-family: "Cabin-SemiBold";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #1f2937;
}
.MostPopularBlogCardInfo span {
  font-family: "RedHatDisplay-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #4b5563;
}

@media only screen and (max-width: 600px) {
  .MostPopularBlogCardInfoBox {
    width: 180px;
  }
  .MostPopularBlogCardTitle {
    font-size: 14px;
  }
}
