.BlogCardContainerBtn{
  padding: 5px 20px;
  height: 56px;
  border-radius: 999px;

  font-family: "Cabin-Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;

}
.BlogCardContainerBtnGreen {
  background: #84cc0a;
  border: 1px solid #84cc0a;
  color: #ffffff;
}
.BlogCardContainerBtnWhite {
  background: #ffffff;
  border: 1px solid #d0d5dd;
  color: #344054;
}

.BlogCardBox {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-template-rows: minmax(100px, auto);
  grid-auto-flow: dense;
  grid-gap: 10px;
}
@media only screen and (min-width: 1200px) {
  .BlogCardBox_Div:first-child {
    grid-column-start: 1;
    grid-column-end: 3;
  }
}

@media only screen and (max-width: 350px) {
  .BlogCardBox {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}

.BlogCardContainer_HR {
  border-bottom: 1px solid #d1d5db;
  width: 100%;
}
