.BlogsPageShadow {
  box-shadow: 0px 20px 25px -5px rgba(16, 24, 40, 0.1),
    0px 8px 10px -6px rgba(16, 24, 40, 0.1);
}

.BlogsPageBorderRadius20px {
  border-radius: 20px;
  border: none;
}

.BlogsPageProfileSocialBox img {
    width: 25px;
  }
@media screen and (max-width: 600px) {
  .BlogsPageProfileSocialBox img {
    width: 20px;
  }
}
