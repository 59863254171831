.CRAMPC {
  background-color: #fafff1;
}
.CRAMPC_CuratedReads {
  width: 65%;
  padding: 10px;
}

.CRAMPC_MostPopular {
  width: 35%;
  padding: 10px;
}

.comment_Row{
  margin-top: 70px;
}

.CRAMPC_Btn {
  background: #fafff1;
  border: 1px solid #d9efb3;
  border-radius: 999px;
 
  color: #84cc0a;
  font-family: "Cabin-Medium";
  font-weight: 500;
font-size: 14px;
line-height: 20px;

}
.CRAMPC_Title {
  font-family: "RedHatDisplay-SemiBold";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #374151;
}
.borderRadius_5px {
  border-radius: 5px;
}

@media screen and (max-width: 600px) {
  .CRAMPC_CuratedReads,
  .CRAMPC_MostPopular {
    width: 100%;
  }
}
