.SignUpBlogBox {
  background: #ffffff;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 8px;
  padding: 24px 38px;
}
.SignUpBlogInputGroup {
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.SignUpBlogInput {
  border-left: 0;
}
.SignUpBlog_Title {
  font-family: "Cabin-SemiBold";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #101828;
}
.SignUpBlog_Des {
  font-family: "Cabin-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #667085;
}
.SignUpBlog_SubDes {
  font-family: "Cabin-SemiBold";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #344054;
}
@media only screen and (min-width: 1200px) {
  .SignUpBlogInputGroup {
    width: 25%;
  }
}

@media only screen and (max-width: 600px) {
  .SignUpBlogBox {
    margin: 0 10px;
  }
}
