.vehichles_container{
   background-color: 
   #F9FAFB; 
}


.vehichles_container .slick-dots {
   
    bottom: 26px;
    
}

.vehichles_container .slick-dots li button:before, .slick-dots li.slick-active button:before {
    color: transparent;
    opacity: 1;
   
  }
 .vehichles_container .slick-dots li {
  margin-left: 30px;;
  }
.vehichles_container   .slick-dots li button:before {
    margin-top: 5px;
    background-color: transparent;
    border: 1px solid #bec1bb;
    border-radius: 18%;
    display: inline-block;
    height: 5px;
    width: 50px;
    background-color:#bec1bb;
  }
.vehichles_container   .slick-dots li.slick-active button:before {
    background-color: #FFFFFF;
    border: 1px solid #FFFFFF;
  }
.vehichles_container p {
  font-family: 'Cabin-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 28px;
    color: #667085;
}

  @media only screen and (max-width: 600px) {
    .hero_img{
     margin-top: 25px;
    }
    .journey_padding{
        margin-top:10px;
    }

    .vehichles_container .slick-dots {
      margin-left: -33px;
    }
     
 }