.Reviews_Container{
    background-color: #FAFFF1;
    border-radius: 20px 20px 20px 20px;
}

.star_wrapper img{
    width:20px;
    margin-left: 5px;
}

.revies_brand{
    width:150px;
}

.reviews_wrapper{
    display: flex;
    justify-content: center;
    flex-direction: column;
}
                
.reviews_img{
border-radius: 0px 20px 20px 0px;
}

.review_wrapper{
    background-color: #FAFFF1;
}

.dot_icon{
    background-color: #84CC0A;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-left: 15px;
}

.dot2_icon{
    background-color: #D0D5DD;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-left: 15px;
}

.review_image{
    border-radius: 0px 20px 20px 0px;
}

.review_Card{
    opacity: 1;
    transform: translateX(0);
}

.review_Card.animate{
    opacity: 1;
    transform: translateX(100%);
    animation-name: smart-animate;
    animation-timing-function:ease-out;
    animation-delay: 2000ms;
}

@keyframes smart-animate {
    from {
        opacity:0;
        transform: translate(-100%);
    }to{
        opacity: 1;
        transform: translate(0%);
    }
}