.scooninja-hero-banner-container {
  background: #FAFFF1;

}


.ninja_bg{
  background-image: url("../../../Assets/image/Assets/Rectangle 24.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
  border-radius: 10px 0px 0px 10px;
  /* border:2px solid #6cac00; */


  
}

/* .slick-list{
  border:2px solid #6cac00;
} */

.ninijaBanner_play{
  width:70px;
  margin-right: 100px;

}



.scooninja-hero-banner-wrapper {
  background: #edf7da;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0px 10px 10px 0px;
 
}

.scooninja-hero-img {
  border-bottom-left-radius: 16px;
  border-top-left-radius: 16px;
}

.scooninja-hero-title {
  font-family: "RedHatDisplay-Medium";
  font-style: normal;
font-weight: 500;
font-size: 46px;
line-height: 61px;
  color: #060705;
}
.scooninja-hero-subtitle {
  font-family: "RedHatDisplay-Regular";
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 64px;
  background: #d9efb3;
  padding: 8px;
  color: #4f7a06;
}

.scooninja-contact-us-btn {
  font-family: "RedHatDisplay-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 50px;

  background: #6aa308;
  /* Primary - Rentals */

  border: 1px solid #84cc0a;
  /* Shadow/xs */

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-family: "Cabin-Regular";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  /* identical to box height, or 156% */

  /* White */

  color: #ffffff;
}


.ninja_btn{
  background: #6AA308;
border: 1px solid #84CC0A;
box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
border-radius: 8px;
font-family: "Cabin-Medium";
font-weight: 500;
padding:9px 20px;
}


.scooninja-hero-banner-container .slick-dots li button:before, .slick-dots li.slick-active button:before {
  color: transparent;
  opacity: 1;
 
}
.scooninja-hero-banner-container .slick-dots li {
margin-left: 30px;;
}
.scooninja-hero-banner-container   .slick-dots li button:before {
  margin-top: 5px;
  background-color: transparent;
  border: 1px solid #FFFFFF;
  border-radius: 15%;
  display: inline-block;
  height: 5px;
  width: 50px;
  background-color:#FFFFFF;
}
.scooninja-hero-banner-container   .slick-dots li.slick-active button:before {
  background-color: #3B5C05;
  border: 1px solid #3B5C05;
  
}

.scooninja-hero-banner-container .slick-track {
  position: relative;
  top: 0;
  left: 13px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

/* .ScooNinjaHeroBanner_Container{
  
  border-radius: 16px;
  overflow: hidden;

  

} */
 /* .ScooNinjaHeroBanner_Container_Main{
  padding: 60px 112px;
  background: #6cac00;
 } */

.ScooNinjaHeroBanner_Img {
  width: 40%;
  
 
}
.ScooNinjaHeroBanner_Img img {
  object-fit: cover;
}
.ScooNinjaHeroBanner_Info {
  width: 60%;
  background: #EDF7DA;
  padding: 52px;




 
}

.ScooNinjaHeroBanner_Btn{
  background-color:#6AA308 ;
  border: 1px solid #84CC0A;
display: flex;
flex-direction: row;
justify-content: center;
/* align-items: center; */
padding: 12px 20px;
gap: 6px;

width: 191px;
height: 52px;
}

.ScooNinjaHeroBanner_BtnIncon{
  width: 15px;
  height: 16.67px;
}
.ScooNinjaHeroBanner_BtnText{
  font-size: 18px;
}


@media only screen and (max-width: 600px) {
  .scooninja-hero-title {
    line-height: 36px;
    color: #060705;
    font-size: 1.4rem;
    margin-top: 15px;
}

.ninja_bg{
  height: 50vh;
}

.container_ninja{
 padding-top: 100px;
}

.scooninja-hero-banner-container .slick-dots{
  margin-left: -55px;
 }

 .ninijaBanner_play{

  margin-right: 50px;

}
}




@media only screen and (min-width: 600px) and (max-width: 768px) {
  .homebanner_container{
   padding:30px 30px;
  }
 }

