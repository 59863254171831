.blog_bg{
  background-color: #F9FAFB;
}


.bold_head{
  font-family: "RedHatDisplay-Bold"!important;
  font-weight: 700;
  font-size: 24px;
}

.line_hr{
  border-top: 1px solid #D1D5DB;
  width: 100%;
  margin-top: 18px;
  margin-left: 11px;
  border-width: 1.5px;
}

.offer_btn{
align-items: flex-start;
padding: 7px 12px;
gap: 8px;
background: #060705;
border-radius: 999px;
border:1px solid #060705;
font-family: "Cabin-Medium";
font-size: 14px;
}

.blog_Title{
 font-family: 'RedHatDisplay-SemiBold';
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 26px;

/* Neutral/800 */

color: #1F2937;
}

.day_title{
    color: #6B7280;
    font-family: 'Cabin-Regular';
    font-size: 13px;
}

.blog_card{
    background: #F9FAFB;
  box-shadow: 0px 20px 25px -5px rgba(16, 24, 40, 0.1), 0px 8px 10px -6px rgba(16, 24, 40, 0.1);
}

.dot_title{
  margin-left: 10px;
}

.social_icon{
padding:0;
  display: flex;
}

.social_icon > div{
margin-left: 8px;
}

@media only screen and (max-width: 600px) {
  .blog_card{
    margin-bottom: 20px;
  }
  .blog_card img{
      width: 100%;
      
  }
   
}

