/* .banner_img img{
    width: 428px;
height: 510px;

} */



.homeRntal_Banner{
  background-color: #FAFFF1;
  
}

.bold_title{
    color:#84CC0A;
    font-family: "RedHatDisplay-Bold";
    font-weight: 700;
    font-size: 48px;
    line-height: 56px;

}

.light_title{
    font-family: "RedHatDisplay-Regular";
font-style: normal;
font-weight: 300;
font-size: 48px;
line-height: 56px;
color: #060705;
}

.unlock_title{
font-family: "RedHatDisplay-Regular";
font-style: normal;
font-weight: 300;
font-size: 48px;
line-height: 56px;
color:
#FFFFFF;
}

.homebanner_container {
    background: url("../../../Assets/image/Assets/lux_banner.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  
  }

  .paraHome{
    color:#FFFFFF;
  }

  .bold_premium{
    color:#D6AB33;
    font-family: "RedHatDisplay-Bold";
    font-weight: 700;
    font-size: 48px;
    line-height: 56px;
  }

  .luxuery_color{
    color:#D6AB33!important;
  }


  
 
  


  .lotticircle{
    width: 200px;
  height: 200px;
  margin: 0 auto;
  border:1px solid red;
  }

  .lotti_wrapper{
    position: relative;
  }
  .lotti_scooter{
    position: absolute;
    top:100px;
    padding: 35px;

  }

  .lottiBanner_light{
    background-color: #FAFFF1;
  }
.lottiBanner_white{
    background-color: #FFFFFF;
}

.lotti_headmax{
    width: 495px;
}

.lottiBanner_section .slick-dots li button:before, .slick-dots li.slick-active button:before {
    color: transparent;
    opacity: 1;
   
  }
 
 .lottiBanner_section .slick-dots li {
  margin-left: 30px;;
  }
.lottiBanner_section   .slick-dots li button:before {
    margin-top: 5px;
    background-color: transparent;
    border: 1px solid #D9EFB3;
    border-radius: 30px;
    display: inline-block;
    height: 5px;
    width: 50px;
    background-color:#D9EFB3;
  }
.lottiBanner_section   .slick-dots li.slick-active button:before {
    background-color: #6AA308;
    border: 1px solid #6AA308;
  }

  .lottiBanner_section .slick-dots {
    position: absolute;
    bottom: 60px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
   
}

.lottiBanner_section .slick-dots{
    margin-left: -40px;
  }


  @media only screen and (max-width: 600px) {

    .lotti_headmax{
      width: 324px;
    margin-top: 30px;
  }


  .luxuery_color{
    font-size: 14px!important;
  }

  .unlock_title{
    font-size: 2.5rem;
    line-height: 45px;
  }

  .bannerScooty_padding{
   margin-top: 30px;
  }
  .containerGeenBanner {
    margin-top: 50px;
    
  }

  .bold_title{
    font-size: 27px;
    line-height: 26px;

  }
  .light_title{
    font-size: 25px;
    line-height: 35px;
  }
    
  .banner_para{
    font-size: 1rem;
    line-height: 25px;
  }
  .lottiBanner_section .slick-dots{
    margin-right: -30px;
  }

  .scoluxImg img {
    padding:30px;
  }

  
  }


  @media only screen and (min-width: 600px) and (max-width: 768px) {
   .homebanner_container{
    padding:30px 30px;
   }

  
   .lotti_headmax {
    width: 390px;
}

.luxe_header{
  font-size: 11px!important;
  line-height: 24px!important;
  width:275px;

}
.unlock_title{
  font-size: 26px;
  line-height: 36px;

}
.bold_premium{
  font-size: 32px;
  line-height: 40px;
}

.bold_title {
  color: #84CC0A;
  font-family: "RedHatDisplay-Bold";
  font-weight: 700;
  font-size: 35px;
  line-height: 45px;
 
}
.light_title {
  font-family: "RedHatDisplay-Regular";
  font-style: normal;
  font-weight: 300;
  font-size: 38px;
  line-height: 45px;
  color: #060705;
}
  }

  @media only screen and (min-width: 768px) and (max-width: 992px) {
    .containerGeenBanner {
      margin-top: 90px;
      
    }
  }