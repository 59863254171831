.RelatedLinks_Title{
  font-family: 'RedHatDisplay-Bold';
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 36px;
color: #374151;
}
.RelatedLinksBox span{
font-family: 'RedHatDisplay-Regular';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;
color: #6AA308;
}

.RelatedLinksBox{
gap: 24px;
}

@media only screen and (max-width: 600px) {
  .RelatedLinksBox{
    gap: 10px;
    }
}
