.hering_head{
    font-size: 35px;
    font-family: 'RedHatDisplay-Bold';
    font-style: normal;
    font-weight: 700;
    line-height: 45px;
    color: #101828;
}

.hiring_para{
 font-family: 'Cabin-Regular';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 30px;
/* identical to box height, or 150% */

text-align: center;

/* Gray/500 */

color: #667085;
}

.learwrapper_btn{
    margin-top: 40px;
}

.learn_more_title{
    padding:20px 20px;
    color: #344054;
}

.view_title{
    padding:10px 20px;
}