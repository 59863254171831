.LatestPostBlogCardImgBox {
  width: 140px;
  height: 96px;
  border: none;
}
.LatestPostBlogCardImgBox img {
  object-fit: cover;
}
.LatestPostBlogCard_Title {
  font-family: "RedHatDisplay-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #1f2937;
}
.LatestPostBlogCard_Info span {
  font-family: "Cabin-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #4b5563;
}

.LatestPostBlogCardInfoBox {
  width: 560px;
  margin-left: 15px;
}
@media only screen and (max-width: 600px) {
  .LatestPostBlogCardImgBox {
    width: 100px;
    height: auto;
  }
  .LatestPostBlogCardInfoBox {
    width: 200px;
  }
  .LatestPostBlogCard_Title {
    font-size: 12px;
  }
  .LatestPostBlogCard_Info span {
    font-size: 10px;
  }
}
