.preneur-bg{
    background-color: #FBF7EB;
}

.preneur-bg h3{
color: #101828;
font-family: "RedHatDisplay-Bold";
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 50px;

}

.preneur-bg p{
    font-family: "Cabin-Regular";
    color: #475467;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    }