.scooPara_Wrapper p {
    font-family: 'Cabin-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: #475467;
    text-align: left;
    padding-right: 0rem;
    padding-left: 0rem;
    margin-right: 26px;

}