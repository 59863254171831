.scoo-luxe-testimonial-wrapper {
  padding: 24px 112px;
}

.scoo-luxe-testimonial-title {
  font-family: "RedHatDisplay-Regular";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #101828;
}

.scoo-luxe-testimonial-content-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #f9f2e0;
  border-radius: 24px;
}
.scoo-luxe-testimonial-content {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px 112px;
}

.scoo-luxe-testimonial-content-para {
  font-family: "RedHatDisplay-Regular";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #101828;
}

.scoo-luxe-testimonial-content-user {
  font-family: "Cabin-regular";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #475467;
}

.scoo-luxe-testimonial-img {
  border-bottom-right-radius: 24px;
  border-top-right-radius: 24px;
}

.testimonialDot_Wrap{
  background-color: var(--white);
  border-radius: 20px;
  width: 73px;
  padding: 15px 2px;
}

.dottest_icon{
  background-color:var(--normal);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-left: 15px;
}


@media only screen and (max-width: 600px) {
 .testo_font{
  font-size: 16px;
  line-height: 25px;
 }


  
}
