.SearchBlogBox {
  height: 148px;
  background-image: url("/public/Images/Blogs/blog-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 20px;
  padding: 54px 48px;
}

.SearchBlogInputBox {
  height: 60px;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 118px;
}
.SearchBlogInputGroup {
  height: 50px;
  box-shadow: 0px 24px 48px -12px rgba(16, 24, 40, 0.25);
  border-radius: 4px;
}

.SearchBlogContainerBG_Text {
  font-family: "RedHatDisplay-SemiBold";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
}
