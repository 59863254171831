.rentnow-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #000;
  padding: 60px 112px;
}

.rentnow-rent-btn {
  font-family: "Cabin-Regular";
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  width: fit-content;
  text-align: left;
  color: #d6ab33;
  background-color: rgba(171, 137, 41, 0.2);
  border: 1px solid #d6ab33;
  border-radius: 8px;
}
.rentnow_para {
  color: #fff;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  font-family: "RedHatDisplay-Regular";
  text-align: center;
}
