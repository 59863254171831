.currnt_icon{
    width: 16px;
}


.list{
    color:#1D2939;
    font-family: 'Cabin-Regular';
    font-style: normal;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 24px;
    padding: 3px 5px;

}