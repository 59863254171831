.rent_wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}



.rentwrapper{
    position: relative;
}

.ranterBanner_wrap{
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 2rem;
    color: #ffffff;
    z-index: 1;
}

.rentBanner_head {
    color: var(--white);
    font-family: "RedHatDisplay-SemiBold";
    font-style: normal;
    font-size: 1.5rem;
    line-height: 40px;
}

.rentIcon_wrap{
    display: flex;
    justify-content: center;
    align-items: center;
}



.RentContainer .slick-dots {
    right: 40px;
    bottom: 26px;
    
}

.RentContainer .slick-dots li button:before, .slick-dots li.slick-active button:before {
    color: transparent;
    opacity: 1;
   
  }
 .RentContainer .slick-dots li {
  margin-left: 30px;;
  }
.RentContainer   .slick-dots li button:before {
    margin-top: 5px;
    background-color: transparent;
    border: 1px solid #bec1bb;
    border-radius: 18%;
    display: inline-block;
    height: 5px;
    width: 50px;
    background-color:#bec1bb;
  }
.RentContainer   .slick-dots li.slick-active button:before {
    background-color: #FFFFFF;
    border: 1px solid #FFFFFF;
  }



  @media (min-width: 650px) and (max-width: 992px) {
    .rentBanner_head {
        font-size: 1rem;
        line-height: 16px;
       
    }
}



  



  @media only screen and (max-width: 600px) {
    .rentBanner_head {
        font-size: 1rem;
        line-height: 16px;
       
    }

    .rentBanner{
        height: 180px;
    }
    .RentContainer .slick-dots {
        right: 33px;
        bottom: 4px;
    }

    .ranterBanner_wrap {
        width: 100%;
    }
    .rentBanner_head{
        padding:2px 10px;
     }                   
     
 }

 @media only screen and (min-width: 600px) and (max-width: 768px) {
    .rentBanner_head {
        font-size: 1rem;
        line-height: 16px;
      
       
    }
    .RentContainer .slick-dots {
        right: 33px;
        bottom: 4px;
    }
  }
 @media only screen and (min-width: 768px) and (max-width: 992px) {
    .rentBanner_head {
        font-size: 1rem;
        line-height: 16px;
        
       
    }
    .RentContainer .slick-dots {
        right: 33px;
        bottom: 4px;
    }
  }



