.story_head{
font-family: 'RedHatDisplay-Bold';
font-style: normal;
font-weight: 700;
font-size: 18px;
line-height: 22px;
text-align: center;
color: #84CC0A;
}

.story_img{
  width: 100%;
    height: 456px;
    /* margin-left: 34px; */
    margin-bottom: 30px;
}

.storyCard_section{
  position: relative;
}
.story_head {
    display: inline-block;
    position: relative;
    padding: 20px 30px;
  }

  /* .story_Card .slick-prev:before, .slick-next:before {
    position: absolute;
    font-family: 'slick';
    font-size: 53px;
    line-height: 1;
    opacity: .75;
    color: rgb(185, 14, 14);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    bottom:40%;
   
} */


/* .story_head:before
 {
    content: '';
    display: block;
    position: absolute;
    width: 573px;
    border-bottom: 1px solid #D9EFB3;
    height: 5px;
    top: 50%;
    margin-top: -4px;
    margin-left: 149px;
} */


.story_head:before {
    right: 95%;
  }

  .story_head:after {
    left: 20%;
  }

  .story_Card{
    background: #FFFFFF;
box-shadow: -100px 100px 150px rgba(52, 64, 84, 0.12);
border-radius: 16px;
  }

  .story_header{
    margin-left: 30px;
  }

  .story_head:before {
    content: '';
    display: block;
    position: absolute;
    width: 555px;
    border-bottom: 1px solid #D9EFB3;
    height: 5px;
    top: 50%;
    margin-top: -4px;
    margin-left: 173px;
}
.story_head:after {
    content: '';
    display: block;
    position: absolute;
    width: 565px;
    border-bottom: 1px solid #D9EFB3;
    height: 5px;
    top: 50%;
    margin-top: -4px;
    margin-left: 150px;
}

.icon_arrows{
    width:50px;
    cursor: pointer;
}


@media only screen and (max-width: 600px) {

  .story_img {
    width: 100%;
    height: auto;
    margin-left: 5px;
    margin-bottom: 10px;
}

.story_Card p {
  margin-left: 5px;
}
}



 /* @media only screen and (min-width: 768px) and (max-width: 992px) {
  .story_img{
    width: 100%; 
    height: 456px;
   margin-left: 4px; 
    margin-bottom: 30px;
    border:1px solid red;
  }
    
} */
