.ScooPageIoTContainer{
    padding: 20px 0;
}


.ScooPageIoT_Img {
  width: 40%;
}
.ScooPageIoT_Info {
  width: 60%;
  padding: 15px;
}
.ScooPageIoT_InfoTitle {
  font-weight: 600;
  font-size: 24px;
  color: #101828;
}

.scooTio_bg{
  background-color:#FFFFFF;
}

.iot_Card{
  padding-right: 3px;
}

.letscooCard_left{
  margin-left: 90px;
}

.ScooPageIoTItem {
  /* width: 202.67px;
  height: 230px; */
  background: #ffffff;
  border: 1px solid #e4e7ec;
  border-radius: 8px;
}
.ScooPageIoTItem p {
  font-weight: 700;
  font-size: 18px;
  color: #101828;
  font-family: "RedHatDisplay-Bold";
  font-size: 18px;
}
.ScooPageIoTItem span {
  font-family: 'Cabin-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
    color: #667085;
  }

  .ScooPageIoT_IconImg{
    width: 64px;
    height: 64px;
  }

  .scoocard_description{
    width: 152px;
   height: 110px;
 font-family: "Cabin-Regular";
 font-style: Regular;
 font-size: 16px;
line-height: 24px;
text-align: Left;
color:#667085;


  }

.iotImg{
  width: auto;
}

.circle_img{
  width: 113%;
}

@media screen and (max-width: 600px) {
  /* .prenure_bg{
    display: none;
  } */
  .ScooPageIoT_Img,
  .ScooPageIoT_Info {
    width: 100%;
  }
  .ScooPageIoTItem{
    width: 100%;
    height: auto;
  }

  .letscooCard_left {
    margin-left: 10px;
}

.scoocard_description{
  width: auto;
height: auto;
}
}

@media only screen and (min-width: 1200px) {
  .ScooPageIoTContainer {
    /* padding-left: 116px; */
    /* padding-right: 116px; */

    padding-left: 50px;
    /* padding-right: 50px; */
  }
}
