.whybecomescooninja-container {
  background: #ffffff;
  padding: 10px;
}
.whybecomescooninja-title {
  font-family: "Cabin-Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 24px;
    color: #475467;
}

.WHYSection_Card{
  border: 1px solid #d9dbdd;
 border-radius: 8px;
 transition: transform 0.2s ease-in-out;

}


.WHYSection_Card:hover{
  transform: scale(1.1);
  cursor: pointer;
  background: #EDF7DA;
  margin-left: 30px;
  margin-right: 30px;




}

.icon_why{
  width:50px;
}

.WhyBecomeScooNinjaHr {
  width: 890px;
  height: 0px;
  border: 0.5px solid #d1d5db;
}

.card-content-wrapper {
  width: 220px;
  height: 220px;
  border: 1px solid #d0d5dd;
}

.card-content-title {
  font-family: "RedHatDisplay-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #101828;
}

.become_title{
  flex-wrap: nowrap!important;
  width: 100%;
  border:1px solid green;
}

.card-content-para {
  font-family: "Cabin-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #667085;
  
height: 72px;
}

.WhyBecomeScooNinja_Icons {
  width: 48px;
  height: 48px;
}
.WhyBecomeScooNinja_Img {
  width: 300px;
  max-width: 300px;
  
}


@media only screen and (min-width: 1200px) {
  .whybecomescooninja-container {
    padding-left: 112px;
    padding-right: 112px;
  }
}

@media screen and (max-width: 600px) {
  .WhyBecomeScooNinja_Img,
  .WhyBecomeScooNinja_Info {
    width: 100%;
  }

  .card-content-wrapper {
    width: 100%;
    height: auto;
  }

  .card-content-para{
    height: auto;
    width: auto;
  }
}
