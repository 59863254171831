.userHeder_wrapper{
    background: #F04438;
border: 1px solid #F04438;
border-radius: 8px 0px 0px 8px;
text-align: center;
color:var(--white);
}
/* .petral_head{
    margin-left: 35px;
} */

.left_line{
width: 100%;
height: 30px;
color:#F04438
}

.line_title{
    margin: 1rem 0;
    color: inherit;
    border: 0;
    border-top: 1px solid;
    opacity: .25;
    font-weight: bold;
    width: 320px;

}

.center_wrapper{
    justify-content: center;
    display: flex;
    align-items: center;
}

.line_img{
    width: 260px;
    padding: 10px;
}

.monthly_titlte{
    font-family: 'Cabin-Regular';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 21px;
/* identical to box height, or 150% */

display: flex;
align-items: center;

/* Error/400 */

color: #F97066;
}

.montht_amout{
    font-family: 'RedHatDisplay-SemiBold';
font-style: normal;
font-weight: 600;
font-size: 24px;
line-height: 36px;


display: flex;
align-items: center;


color: #F97066;
}

.petrol_Card{
display: flex;
flex-direction: column;
justify-content: center;
align-items: flex-start;
padding: 16px;
gap: 8px;
/* width: 276px;
height: 112px; */
border: 1px solid #FECDCA;
border-radius: 8px;
}

.petrolgreen_Card{
    display: flex;
flex-direction: column;
justify-content: center;
align-items: flex-start;
padding: 16px;
gap: 8px;
/* width: 276px;
height: 112px; */
border-radius: 8px;
    border: 1px solid #D9EFB3;
}

.petrol_para{
 font-family: 'Cabin-Regular';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;
/* or 150% */


/* Gray/600 */

color: #475467;
}


.user_wrapper{
padding: 16px 48px;
background: #84CC0A;
border: 1px solid #84CC0A;
border-radius: 0px 8px 8px 0px;
}

.user_bg{
    background: #F6FEF9;
    padding-bottom: 10px;
}

.text_change{
    color: #6AA308;
}

.monthly_amout{
font-family: 'RedHatDisplay-Bold';
font-style: normal;
font-weight: 700;
display: flex;
align-items: center;
color: #6AA308;
font-size: 2rem;
}

.line2_img{
    width: 125px;
  
    padding: 0;
    margin-right: 11px;
}