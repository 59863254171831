.ScooPagePartnershipContainer {
  padding: 20px 0;
}
.ScooPagePartnership_Img {
  width: 30%;
  padding: 15px;
}
.ScooPagePartnership_Info {
  width: 70%;
  padding: 15px;
}

.letcoo_plan{
  padding:12px;
  font-family: "RedHatDisplay-SemiBold";
}
.ScooPagePartnership_InfoTitle {
  font-weight: 600;
  font-size: 24px;
  color: #101828;
}
.ScooPagePartnership_InfoDes {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #475467;
}

.ScooPagePartnership_Plan {
 
  height: auto;
  background: #ffffff;
  border: 1px solid #e4e7ec;
  border-radius: 8px;
}
.ScooPagePartnership_PlanTitle1,
.ScooPagePartnership_PlanTitle2 {
  font-weight: 500;
  font-size: 20px;
  color: #ffffff;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px
}
.ScooPagePartnership_PlanTitle1 {
  background: #639908;
}
.ScooPagePartnership_PlanTitle2 {
  background: #98a2b3;
}
.ScooPagePartnership_PlanDes1,
.ScooPagePartnership_PlanDes2 {
  font-weight: 600;
  font-size: 18px;
  line-height: 36px;
  color: #101828;
}
.ScooPagePartnership_PlanDes1 {
font-family: "RedHatDisplay-SemiBold";
font-style: normal;
font-weight: 600;
font-size: 24px;
line-height: 36px;
background: #EDF7DA;
padding:15px 15px;


text-align: center;



color: #101828;
}
/* .ScooPagePartnership_PlanDes2 {
  background: #e4e7ec;
} */

.ScooPagePartnership_PlanDes1 span {
  font-family: "RedHatDisplay-Bold";
  color: #639908;
}
.ScooPagePartnership_PlanDes2  {
  font-family: "RedHatDisplay-SemiBold";
font-style: normal;
font-weight: 600;
font-size: 24px;
line-height: 36px;
background: #E4E7EC;
padding:15px 15px;
/* identical to box height, or 150% */

text-align: center;

/* Gray/600 */

color: #475467;
}

.scooPartner_img img{
  height: 550px;
  padding-right: 20px;
}

.become_btn{
  background: #6aa308;
    border: 1px solid #84cc0a;
    border-radius: 8px;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    font-family: "Cabin-Medium";
    padding: 10px 20px;
    font-weight: 500;
font-size: 18px;
line-height: 28px;
/* identical to box height, or 156% */


/* White */

color: #FFFFFF;
}

.letScoo_describ{
font-family: "RedHatDisplay-Regular";
  font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 26px;
color: #475467;
}

.letScoo_number{
  font-family: "RedHatDisplay-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
}
.ScooPagePartnership_TickItem {
  border-bottom: 1px solid #e4e7ec;
}

.ScooPagePartnership_TickItem p span {
  color: #475467;
  font-weight: 700;
}
.ScooPagePartnership_IconImg{
  width: 24px;
  height: 24px;
}

@media only screen and (min-width: 1200px) {
  .ScooPagePartnershipContainer {
    /* padding-left: 116px;
    padding-right: 116px; */
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media screen and (max-width: 600px) {
  .ScooPagePartnership_Img,
  .ScooPagePartnership_Info {
    width: 100%;
  }
  .ScooPagePartnership_Plan {
    width: 100%;
    height: auto;
  }

  .scooPartner_img img{
    height: auto;
    padding-right: 10px;
   
  }
}
