.product-wrapper {

  background-color: var(--white);
}

.product_Card{

border-radius: 8px;
display: flex;
justify-content: center;
align-items: center;
padding: 20px 20px;
background: #FFFFFF;
/* Luxe/Light :active */

border: 1px solid #F2E5C0;
border-radius: 8px;


cursor: pointer;
}

.product_rows{
  position: relative;
}

.product_rows .Prev_ARROW{
  position: absolute;
    left: 85%;
    top: 50%;

width:50px;
}

.product_rows .nxt_ARROW{
  position: absolute;
    z-index: 1;
    top: 50%;
    left: 5%;
  width:50px;
  }

.pro_Title{
  color: #060705;
  font-family: "RedHatDisplay-Medium";
  font-style: normal;
font-weight: 600;
font-size: 24px;
line-height: 36px;
}

/* .qutityScooty_img .slick-next:before {
  content: url('../../../Assets//image/plan/arrowLeft.png');
  border:1px solid red;
  
}

.qutityScooty_img .slick-prev::before{
  content: url('../../../Assets//image/plan/arrowLeft.png');
}

.qutityScooty_img .slick-next {
  right: -0px;
}
.qutityScooty_img .slick-prev {
  left: 15px;
} */

.qutityScooty_img img{

 width:300px;
 margin: auto;
}

.rent_nowlux{
  color: #C19A2E!important;
  font-style: normal;
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 24px;
}

.productRent_lnik{
  width: 100%;
}

.productRent_btn{
  background: #D6AB33!important;
border: 1px solid #D6AB33!important;
box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
border-radius: 8px;
width:100%;
padding: 10px 10px;
}

.product-title {
  color: #101828;
  font-family: "RedHatDisplay-Regular";

  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 48px;
}
.product-card-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 36px 24px;
  gap: 4px;
  isolation: isolate;
  border: 1px solid #f2e5c0;
  border-radius: 8px;
}
.product-card-title {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  gap: 8px;
  background: #f9f2e0;
  color: #c19a2e;
  min-width: 300px;

  border: 1px solid #f2e5c0;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.product-item-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.product-image-nav {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.product-card-arrow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 12px;
  color: #fff;
  background: #d6ab33;
  width: 44px;
  height: 44px;

  border-radius: 999px;
}

.product-name-title {
  font-family: "RedHatDisplay-Regular";

  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  /* identical to box height, or 150% */

  text-align: center;

  /* Black/Normal */

  color: #060705;
}
.product-price-title {
  font-family: "RedHatDisplay-Regular";
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;
  /* identical to box height, or 117% */

  text-align: center;

  /* Gray/900 */

  color: #101828;
}
.product-text-per {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  text-align: center;

  /* Gray/600 */

  color: #475467;
}

.product-rent-now-btn {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  gap: 8px;

  width: 341.33px;
  height: 44px;

  /* Luxe/Normal */

  background: #d6ab33;
  /* Luxe/Normal */

  border: 1px solid #d6ab33;
  /* Shadow/xs */

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

@media only screen and (max-width: 600px) {
  .plan_head{
    font-size: 1.1rem;
    line-height: 20px;
  }


  

    .product_rows .Prev_ARROW{
    
      right: -10%;
    width:30px;
    }
    
    .product_rows .nxt_ARROW{
     
        left: -4%;
      width:30px;
      }


  
}
