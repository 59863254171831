.member-bg{
    background: url(../../Assets/image/club/memberBg.png);
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;

}
.member-para{
    color: var(--black-light, #E6E6E6);
    font-family: "Cabin-Regular";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
}
.member-head{
    color: var(--luxe-normal, #D6AB33);
    font-family: "RedHatDisplay-Medium";
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 44px; 
}
.mem-sec{
    padding: 40px;
    margin-left: 36px;
    margin-top: 25px;
}
.video-img{
   width: 80%;
   height: 65%;
   margin-top: 130px;
   margin-left: 11px;
}

.mem-sec {
    padding: 40px;
    margin-left: auto; 
    margin-top: auto; 
}
@media screen and (max-width: 600px) {
    .video-img {
        width: 100%;
        padding: 20px 20px;
        margin-top: 0px;
        margin-left: 0px;
        height: 100%;
        margin-bottom: 5px;
    }
}