.whyRentFromUs-container {
  background: #f9f2e0;
}

.whyRentFromUs-heading {
  font-family: "RedHatDisplay-SemiBold";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #101828;
}

.whyRentFromUs-para {
  font-family: "Cabin-Regular";
  color: #475467;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

@media only screen and (max-width: 600px) {
 
}
