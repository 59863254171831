.navbar_Container {
  /* box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06); */
  align-items: center;
  padding: 0px 100px;
 background-color: var(--white);



}

/* .show{
  background-color: red;
} */
              
.hide_nav{
  display: none!important;
}

.active_menu{
  color:var(--greenactive) !important;
  font-family: "Cabin-Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-right: 10px;
}

.navbar_Wrapper {
  justify-content: flex-end;
  align-items: end;
}



.menu_title {
  font-family: "Cabin-Medium";
  color: #667085;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-right: 2px;
}

/* .menu_title:hover{
 color:green!important;
 
} */
.menu_title_golden{
  color: #D6AB33;
}



.navbar-nav{
  margin-bottom: 2px;
  margin-right: 4px;

}

.rent_img{
  width: 20px;
  height: 20px;
  margin-bottom: 4px;


}

.menu_title_golden:focus{
  color: #D6AB33;
}

.rent_btn{
 background-color: var(--green);
 color:var(--white);
 border:1px solid var(--green);
 border-radius: 8px;
 padding: 8px 18px;
 font-family: "Cabin-Medium";
 font-weight: 500;
 align-items: center;
 
}
.rent_btn_golden{
  background-color: var(--normal);
  border:1px solid var(--normal);
  color:var(--white);
  border-radius: 8px;
  padding: 8px 18px;
}

.rent_btn_golden:hover{
  background-color: var(--normalhover)!important;
  
}



@media only screen and (max-width: 600px) {

  .navbar_Container {
    padding: 1px 2px;
  
  }

  .navbar-nav{
    
    width: 100%;
  }


  /* .navbar-collapse{
    display: flex;
      align-items: start;
      justify-content: start;
      flex-direction: column;
    text-align: left;
    padding: 10px 0;
  

  } */

  .menu_title {
    margin-right: 30px;
    cursor: pointer;
  }

  .link_wrapper {
    margin-bottom: 10px;
    border-bottom: 1px solid grey;
    padding: 6px 0;
}

.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow:none!important;
}

.navbar-toggler {
border:1px solid #ffff;
}

.rent_bottom{
  margin-bottom: 10px;
}
}
