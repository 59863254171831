

.homeebike_header{
    font-family: "RedHatDisplay-Bold";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    display: flex;
    align-items: center;
    color: #101828;

}


.homeebike_wrapper p{
    font-family: 'Cabin-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #475467;
}

.homeebike_container{
    background: #f2f4f7;
}

@media only screen and (max-width: 600px) {
    
    .homeebike_padding{
        margin-top:10px;
    }

    .homeebike_wrapper{
        height: 50vh;
    }
     
 }