.policy_Container{
    text-align: left;
    color: #0f0f0f;
    
}



.policy_Container h5{
    font-size: 1.2rem;
    font-family: sans-serif!important;
    font-weight: bold!important;

}

.policy_Container p{
  font-family: sans-serif!important;
}

.privacy_header{
    font-family: 'RedHatDisplay-SemiBold';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    display: flex;
    align-items: center;
    color: #101828;
}

.privacy_para{
    font-family: 'Cabin-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 24px;
   
}

.privacy_head{
    font-family: 'RedHatDisplay-Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 36px;
    color: #101828;
}


   