.hero-banner-container {
  background: url("../../../Assets/image/Assets/scooluxe-hero-banner.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right 50% top 55%;

}

.scooter {
  animation-name: scoot;
  animation-duration: 4s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}


@keyframes scoot {
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(800px);
  }
  100% {
    transform: translateX(0);
  }
}


.scooter2 {
  animation-name: scoot;
  animation-duration: 4s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}


@keyframes scoot2 {
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(800px);
  }
  100% {
    transform: translateX(0);
  }
}

.luxe_header{
 display: flex;
flex-direction: row;
align-items: flex-start;
padding: 8px 16px;
gap: 8px;
width: 400px;
height: 40px;
background: rgba(171, 137, 41, 0.2);
border-radius: 999px;
color:var( --normal)!important;
margin-bottom: 15px;
}

.link_lux{
  color:#D6AB33!important;
}
/* 
.link_lux:hover{
  color:#D6AB33;
  background: rgba(171, 137, 41, 0.2)!important;
} */

.luxe_btn:hover{
  color:#D6AB33;
  background: rgba(171, 137, 41, 0.2)!important;
}

.hero_img img{
  margin-left: 70px;
}

.luxebtn_Wrapper{
  margin-top: 30px;
}

.heroBanner_wrapper{
  margin-top: 70px;
}

.luxe_para{
  color:var(--lightpink)
}

.luxe_premium{
  color:var( --normal);
}

.luxe_title{
  color:var(--white);
}

.hero_img{
  padding: 70px;
}

.luxe_btn{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 18px;
  gap: 5px;
  color:var(--normal);
  background: rgba(214, 171, 51, 0.2);
  border: 1px solid #D6AB33;
  font-family: "Cabin-Medium";
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}


.hero-banner-support_Title {
  font-family: "Cabin-Regular";
  color: #d6ab33;
  background-color: rgba(171, 137, 41, 0.2);
  width: 312px;

  font-size: 14px;
  padding: 6px;
  border-radius: 25px;
}

.hero-banner-banner_header {
  width: 634px;
  height: 168px;
  color: #fff;
  font-family: "RedHatDisplay-Regular";
}
.hero-wrapper {
  width: 634px;
}
.hero-banner-banner_sub_header {
  color: #d6ab33;
  font-size: 48px;
  font-family: "RedHatDisplay-SemiBold";
}
.hero-banner-banner_para {
  color: #fbf7eb;

  font-family: "Cabin-Regular";
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
}
.hero-banner-rent-btn {
  font-family: "Cabin-Regular";
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #d6ab33;
  background-color: rgba(171, 137, 41, 0.2);
  border: 1px solid #d6ab33;
  border-radius: 8px;
}
.hero-banner-rent-img {
  color: #d6ab33;
  width: 18px;
  height: 18px;
}

@media only screen and (max-width: 600px) {
  .hero-banner-container {
   background-position: center left;
   
  
  }

  .luxe_header{
   width:350px;
   font-size: 15px!important;
   padding: 8px 14px;
  }

  .hero_img img{
    margin-left: 0px;
    width:100%
  }

  .hero_img {
    padding: 2px;
}
}
