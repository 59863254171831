.servicebanner_container {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background: linear-gradient(-90deg, transparent 26%, #d9efb3 65%),
    url("../../Assets//image/service/service.png") center/cover no-repeat;
  height: 600px;
  margin-top: 80px;
  padding: 60px;
}
.banner_head {
  width: fit-content;
  height: 36px;
  padding: 8px 16px 8px 16px;
  border-radius: 999px;
  border: 1px 0px 0px 0px;
  color: #666666 !important;
  font-family: "Cabin_Medium";
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  background: var(--white);
  border: 1px solid #6aa308;
}
.header_banner {
  font-family: "RedHatDisplay-Medium";
  font-size: 55px;
  font-weight: 700;
  line-height: 60px;
  text-align: left;
  color: #62940e;
  display: inline;
  width: 100%;
  white-space: nowrap;
}
.scoo_head-eservice {
  font-family: "RedHatDisplay-bold";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  display: flex;
  align-items: center;
  color: #101828;
}
.header_span {
  font-family: "RedHatDisplay-Regular";
  font-size: 40px;
  font-weight: 400;
  line-height: 60px;
  text-align: left;
}

.header_para {
  font-family: "Cabin-Regular";
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  color: #1d2939;
}

.offerd-servies {
  padding: 4rem 0rem;
  background: #d9efb3;
}

.offered-services-card {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 140px;
  padding: 12px;
  border-radius: 8px;
}
.offered-services-card > div {
  border: 1px solid #6aa308;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  background: #d9efb3;
  height: 60px;
  width: 60px;
  padding: 1rem;
  align-items: center;
}
.offered-services-card > div > img {
  height: 40px;
  object-fit: contain;
  width: 40px;
}

.offered-services-card-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
}

.offered-services-card > span {
  font-family: "RedHatDisplay-Bold";
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  height: 45px;
}

.offered-hed {
  font-family: "RedHatDisplay-Bold";
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
}

.soco_header {
  font-family: "RedHatDisplay-Bold";
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  text-align: left;
}
.location {
  height: 20px;
  width: 20px;
}
.location-card {
  width: auto;
  height: 250px;
  padding: 24px 48px 24px 48px;
  gap: 1rem;
  border-radius: 8px;
  border: 1px solid #e4e7ec;
  background: #f9fafb;
  margin: 0rem;
}
.location-card > h3 {
  font-family: "Cabin-Regular";
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
}
.location-card > span {
  font-family: "Cabin-bold";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #6aa308;
}
.location-card > p {
  font-family: "Cabin-Regular";
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #666666;
}

.scooevhub-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  padding-left: 5rem;
  padding-right: 5rem;
}

.scooevhub-container > div {
  margin: 1rem;
}
.location-cards-warpper {
  margin-top: 2rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  padding-left: 5rem;
  padding-right: 5rem;
  margin-bottom: 4rem;
}
.scoo-clinic {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
}
.scheduleaService-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  margin-bottom: 4rem;
}
.servicebanner_container_mobile-wrapper {
  display: none;
}

.soco_para {
  font-family: "Cabin-Regular";
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  color: #666666;
  margin-bottom: 2rem;
}
@media only screen and (max-width: 768px) {
  .servicebanner_container {
    display: none;
  }
  .servicebanner_container_mobile-wrapper {
    display: flex;
    flex-direction: column;
  }
  .bgimage {
    height: inherit;
    object-fit: contain;
  }
  .servicebanner_container_mobile {
    display: flex;
    flex-direction: column;
    background: #d9efb3;
    padding: 8rem 1rem 1rem 1rem;
  }
  .header_banner {
    font-size: 30px;
    white-space: break-spaces;
    line-height: normal;
  }
  .header_span {
    font-size: 39px;
    line-height: normal;
  }
  .header_para {
    font-size: 1rem !important;
  }
  .offered-services-card-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr) !important;
    gap: 1rem;
    width: 100%;
  }
  .scheduleaService-wrapper {
    grid-template-columns: repeat(1, 1fr) !important;
  }
  .scooevhub-container {
    grid-template-columns: repeat(1, 1fr);
    padding: 1rem !important;
  }
  .location-cards-warpper {
    grid-template-columns: repeat(1, 1fr) !important;
    padding: 1rem !important;
  }
  .scoo-clinic {
    grid-template-columns: repeat(1, 1fr);
    gap: 2rem;
  }
  .offerd-servies {
    padding: 2rem;
  }
  .offered-services-card > span {
    font-size: 16px;
  }
}
@media only screen and (max-width: 400px) {
  .location-card{
    padding: 1rem;
  }
  .offered-services-card-wrapper {
    grid-template-columns: repeat(2, 1fr) !important;
  }
  .offered-services-card > span {
    font-size: 12px;
  }
  .right-side > h2 {
    font-size: 36px;
  }
  .servicebanner_container {
    display: none;
  }
  .servicebanner_container_mobile-wrapper {
    display: flex;
    flex-direction: column;
  }
  .bgimage {
    height: inherit;
    object-fit: contain;
  }
  .servicebanner_container_mobile {
    display: flex;
    flex-direction: column;
    background: #d9efb3;
    padding: 8rem 2rem 2rem 2rem;
  }
  .spotus-cards-wrapper {
    grid-template-columns: repeat(3, 1fr) !important;
  }
  .header_banner {
    font-size: 39px;
  }
  .header_span {
    font-size: 39px;
  }
  .header_para {
    font-size: 1rem !important;
  }
  .banner_head {
    height: auto;
    text-align: center;
  }

  .scooevhub-container {
    grid-template-columns: repeat(1, 1fr);
  }
  .location-cards-warpper {
    grid-template-columns: repeat(1, 1fr) !important;
  }
  .scoo-clinic {
    grid-template-columns: repeat(1, 1fr);
    gap: 2rem;
  }

  .right-side h1 {
    line-height: 36.46px !important;
  }
}
@media only screen and (min-width: 950px) {
  .offered-services-card-wrapper {
    grid-template-columns: repeat(3, 1fr) !important;
  }
  .scooevhub-container {
    grid-template-columns: repeat(2, 1fr);
  }
  .location-cards-warpper {
    grid-template-columns: repeat(2, 1fr) !important;
    margin-top: 4rem;
  }

  .scoo-clinic {
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
  }
  .scheduleaService-wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
  .offerd-servies {
  }
}
