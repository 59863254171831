.support_Title{
    color:var(--green);
    font-family: "Cabin-Medium";
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}

.faq_Title{
    font-family: "RedHatDisplay-Bold";
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 50px;
}

.faq_wrapper p{
    color:#667085;
}

.question_head{
    font-family: "Cabin-Medium";
    font-weight: 500;
font-style: normal;

font-size: 18px;
line-height: 28px;


color: #101828;
cursor: pointer;
}

.answer_title{
    font-family: "Cabin-Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 20px;
    /* text-align: center; */
    color: #475467;
    /* margin-left: 15px; */

}

.accodian_wrapper{
    display: flex;
    justify-content: space-between;
}

.minus_icon{
    width: 20px;
}

.content{
    max-height: 0;
    overflow: hidden;
}

.content.show{
    height: auto;
    max-height: 9999px;
   
    padding-top: 17px;
    
}

            
@media only screen and (max-width: 600px) {
    .support_Title{
        margin-top: 10px;
    }

    .accordiancard{
        padding: 0;
    }

    .answer_title {
      
        line-height: 20px;
        
    }
     
 }