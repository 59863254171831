.contacMap_head{
    font-family: 'RedHatDisplay-Bold';
font-style: normal;
font-weight: 700;
font-size: 48px;
line-height: 45px;
color: #101828;
}

.contactMap_para{
    color: #667085;
    font-size: 20px;
}

.privacy_link{
    font-family: "Inter-Regular";
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;
border-bottom: 1.3px solid #667085;


color: #667085;
}

.privecy_title{
font-family: "Inter-Regular";
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;
/* identical to box height, or 150% */


/* Gray/500 */

color: #667085;
}

.label_title{
font-family: 'Cabin-Regular';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 21px;
color: #344054;
}

:-ms-input-placeholder{
    font-family: 'Cabin-Regular';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;


color: #667085;
}

.input_title {
    padding: 12px 16px;
    background: #FFFFFF;
    border: 1px solid #D0D5DD;
box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
border-radius: 8px;
  }

  .contactSubmit_btn{
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 12px 20px;
width:100%;
gap: 8px;
background: #84CC0A;
border: 1px solid #84CC0A;
box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
border-radius: 8px;
font-family: "Inter-Medium";
  }