.BlogCardOne_Info {
  background: linear-gradient(360deg, #000000 0%, rgba(0, 0, 0, 0) 100%);

  position: absolute;
  bottom: 0;
  color: white;
  padding: 30px;
  width: 100%;
  gap: 12px;
}

.BlogCardBtnTransparent {
  background: rgba(250, 255, 241, 0.2);
  border: 1px solid #d9efb3;
  backdrop-filter: blur(12px);
  border-radius: 24px;

  font-family: "Cabin-Regular";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
}

.BlogCardOneTitle {
  font-family: "RedHatDisplay-SemiBold";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
}
.BlogCardOneDes span {
  font-family: "RedHatDisplay-Regular";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
}

.BlogCardOneImgContainer {
  height: 350px;
  background-position: right;
  background-size: cover;
  background-repeat: no-repeat;
  object-fit: cover;
}
