.BlogOfTheDay_Img {
  width: 39%;
}
.BlogOfTheDay_Img img {
  object-fit: cover;
  border-radius: 16px;
}
.BlogOfTheDayContainer{
  margin-top: 80px;
  margin-bottom: 80px;
}

.BlogOfTheDay_Info {
  width: 59%;
  gap: 12px;
}
.BlogOfTheDayTitle {
  font-family: "RedHatDisplay-SemiBold";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #1d2939;
}
.BlogOfTheDayDescription {
  font-family: "Cabin-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #475467;
}

.BlogOfTheDayReadmore {
  font-family: "Cabin-Regular";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #84cc0a;
}

.BlogOfTheDayProfileBox {
  gap: 10px;
}

.BlogOfTheDayProfileInfoName {
  font-family: "Cabin-semibold";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}
.BlogOfTheDayProfileInfoDate {
  font-family: "Cabin-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #667085;
}
.BlogOfTheDay_Btn {
  padding: 7px 12px;
  background: #fafff1;
  font-family: "RedHatDisplay-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  border: 1px solid #d9efb3;
  color: #84cc0a;
  border-radius: 24px;
}

.BlogsPage_ProfileSocialBox div {
  height: 27px;
  width: 27px;
}

.BlogOfTheDay_Hr {
  border-bottom: 1px solid #e4e7ec;
  width: 59%;
}
@media screen and (max-width: 600px) {
  .BlogOfTheDay_Img,
  .BlogOfTheDay_Info {
    width: 100%;
  }
  .BlogsPage_ProfileSocialBox div {
    height: auto;
    width: 17px;
  }
  .BlogOfTheDay_Hr {
    width: 0%;
  }
}
