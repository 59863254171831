.Scoo-club{
    margin-top: 4rem;
    display: grid;
    justify-content: center;
  }
  .scoo-club-heading{
    color: var(--luxe-normal, #D6AB33);
    text-align: center;
    font-family: "RedHatDisplay-Bold";
    font-size: 48px;
    font-style: normal;
    font-weight: 900;
    line-height: 56px; 
    display: grid;
    justify-content: center;
    width: 76vw;
  
  }
  .Scoo-club h6{
    display: grid;
    justify-content: center;
    color: var(--black-normal, #1C1F18);
    text-align: center;
    font-family: "Cabin-Medium";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
  }
  .Scoo-club p{
    display: grid;
    justify-content: center;
    color: #475467;
    text-align: center;
    font-family: "Cabin-Medium";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  .club-btn{
    color: var(--white, #FFF);
    font-family: "Cabin-Medium";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; 
    border-radius: 8px;
    border: 1px solid var(--luxe-normal, #D6AB33);
    background: var(--luxe-normal, #D6AB33);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    display: flex;
    padding: 10px 18px;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }
  .link_text1{
    justify-content: center;
    display: flex;
  }
  .club-btn:hover{
    border: 1px solid var(--luxe-normal, #D6AB33);
    background: var(--luxe-normal, #D6AB33);
  }




  @media screen and (max-width: 600px) {
    .letScoo_bg {
     padding-top: 0px;
  }
  .scoo-club-heading{
    width: auto;
  }

}
