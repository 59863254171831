.letscoo_bg{
  background-color: #F9FAFB;
}

.letsoo_campus{
  position: relative;
}

.scooPageInfo_head{
  font-family: 'RedHatDisplay-Bold';
    font-style: normal;
    font-weight: 700;
    color: var(--gray-900, #101828);
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: 36px; /* 150% */
}

/* .letsoo_campus::before{
  content: url('../../../Assets/image/logo/Button.png');
  position: absolute;
  left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
} */

.ScooPageInfoOneContainer {
  background: #f9fafb;
  padding: 50px 0;
}
.ScooPageInfoOne_Img {
  width: 40%;
  padding: 15px;
  border: 2px solid #EDF7DA;
  border-radius: 10px;
}
.ScooPageInfoOne_Info {
  width: 60%;
  padding: 15px;
}
.ScooPageInfoOne_InfoTitle {
  font-weight: 600;
  font-size: 24px;
  color: #101828;
}
.ScooPageInfoOne_InfoDes {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #475467;
}

.ScooPageInfoOneTimeDateBox {
  width: 200px;
  height: 96px;
  border: 1px solid #e4e7ec;
  border-radius: 8px;
  
}

.letScoo_btn{
  background: #6aa308;
  border: 1px solid #84cc0a;
  border-radius: 8px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  margin-top: 115px;
  padding: 10px 20px;
}
.letScoo_btn1{
  border-radius: 8px;
  border: 1px solid var(--black-normal-hover, #050605)!important;
  background: var(--black-normal, #1C1F18)!important;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05)!important;
  color: var(--luxe-normal, #D6AB33)!important;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; 
  display: flex;
  padding: 10px 56px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  flex: 1 0 0;
  margin-top: 3rem;
  margin-left: -123px;
  
  }
  .letScoo_btn1:hover{
    border: 1px solid var(--black-normal-hover, #050605);
    background: var(--black-normal, #1C1F18);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }

.letsScoo_Card{
  margin-top: 30px;
}
.ScooPageInfoOneTimeDateBox p {

  font-size: 16px;
  color: #000000;
  font-family: "Cabin-Regular";
  font-weight: 400;
}
.ScooPageInfoOne_Button1 {
  background: #6aa308;
  border: 1px solid #84cc0a;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;

  font-weight: 500;
  font-size: 18px;
  color: #ffffff;
  margin-top: 50px;
}
.ScooPageInfoOne_IconImg{
  width: 26px;
  height: 26px;
}
.scooPageInfo_para{
  color: var(--gray-600, #475467);
  font-family: "Cabin-Regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; 
  margin-right: 26px;
}
.play-btn{
  width: 72px;
  height: 72px;
  position: absolute;
  left: 256px;
  top: 164px;
}


@media screen and (max-width: 600px) {
  .ScooPageInfoOne_Img,
  .ScooPageInfoOne_Info {
    width: 100%;
  }
  .ScooPageInfoOneTimeDateBox {
    width: 100%;
  }
  .ScooPageInfoOne_Button1 {
    margin-top: 30px;
  }

  .letScoo_btn{
  
    margin-top: 35px;
  
  }

  .letsoo_campus{
    margin-top: 10px;
  }
  .letScoo_btn1{
    margin: auto;
    margin-bottom: 22px;
    margin-top: 20px;
  }
}

@media only screen and (min-width: 1200px) {
  .ScooPageInfoOneContainer {
    padding-left: 50px;
    padding-right: 50px;
  }
}
