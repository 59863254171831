.deliverEarn-container {
  background-color: var(--white);
  padding-top: 100px;
}

.banner-container {
  display: flex;
  width: 100%;
  height: 646px;
  position: absolute;
  margin-top: 6.2rem;
  top: 0%;

  background: linear-gradient(63deg, transparent 38%, #1d2939 39%),
    /* url("./../../Assets/image/deliverEarn/BannerImage.gif") */ center/cover
      no-repeat;
}

.left-side {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.right-side > div > p {
  color: #6aa308 !important;
  font-family: "Cabin-Medium";
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  /* width: 432px; */
  height: 20px;
  gap: 0px;
  margin-bottom: 0;
}
.right-side > h1 {
  font-family: "Cabin-Medium";
  font-size: 48px;
  margin-left: 2rem;
  font-weight: 400;
  line-height: 60.46px;
  margin-top: 4rem;
  color: var(--white) !important;
}
.right-side > h2 {
  font-size: 64px;
  font-weight: 700;
  line-height: 70px;
  margin-left: 2rem;
  text-align: left;
  color: #6aa308 !important;
}
.heading-warper {
  padding: 8px 8px 8px 8px;
  border-radius: 18px;
  background: var(--white);
  margin-left: 2rem;
}
.right-side-content {
  display: flex;
  justify-content: end;
  flex-direction: column;
  width: 100%;
  position: absolute;
  top: 20%;
  right: -41%;
}
.right-side-content > div > p {
  color: #6aa308 !important;
  font-family: "Cabin-Medium";
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  /* width: 432px; */
  height: 20px;
  gap: 0px;
  margin-bottom: 0;
}
.right-side-content > h1 {
  font-family: "Cabin-Medium";
  font-size: 48px;
  margin-left: 2rem;
  font-weight: 400;
  line-height: 60.46px;
  margin-top: 4rem;
  color: var(--white);
}
.right-side-content > h2 {
  font-size: 64px;
  font-weight: 700;
  line-height: 70px;
  margin-left: 2rem;
  text-align: left;
  color: #6aa308 !important;
}
.right-side-action {
  margin-left: 2rem;
}
.banner-center-image {
  object-fit: contain;
  width: auto;
  height: 100%;
  max-width: 100%;
  position: inherit;
}
.saveuptotext-image {
  position: absolute;
  right: 447px;
  top: 29%;
  transform: translateY(-50%);
  width: 150px;
  height: 68px;
}

.kyc_requirements_wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
}
.kyc_requirements_wrapper > div {
  padding: 1rem;
  font-family: "Cabin-Medium";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}

.icon-image {
  height: 50px;
  width: 50px;
}
.spotus-cards {
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding: 2rem;
}
.spotus-cards > h1 {
  font-family: "RedHatDisplay-Bold";
  font-size: 18px;
  font-weight: 700;
  line-height: 21.96px;
  text-align: left;
  margin-top: 1rem;
}
.spotus-cards > p {
  font-family: "Cabin-Medium";
  font-size: 16px;
  font-weight: 400;
  line-height: 19.44px;
  text-align: left;
  color: #667085;
}

.download-btn {
  height: 60px;
  gap: 0px;
  border-radius: 8px;
  background: #84cc0a;
  border: 1px solid #84cc0a;
}
.marginTop {
  margin-top: 4rem;
}
.scoo_clinic_m_card_wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  margin-bottom: 2rem;
}

.scoo_clinic_m_card_wrapper > div {
  border: 1px solid #84cc0a;
  width: auto;
  height: 96px;
  padding: 24px;
  gap: 16px;
  border-radius: 8px;
}

.form-text {
  font-family: "Cabin-Regular";
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
}
.banner-container-mobile {
  display: none;
}
.spotus-cards-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
}
@media only screen and (max-width: 400px) {
  .right-side > div > p {
    text-align: center;
    height: auto;
    font-size: 16px;
  }
  .heading-warper {
    padding: 5px;
    margin-left: 0;
    margin: 10px;
  }
  .right-side > h1 {
    font-size: 30px !important;
  }
  .offerd-servies {
    padding: 1rem;
  }

  .scoo_clinic_m_card_wrapper > div {
    height: auto;
    padding: 10px;
  }
  .spotus-cards {
    padding: 1rem;
  }
  .spotus-cards > h1 {
    font-size: 12px;
  }
}
@media only screen and (max-width: 600px) {
  .banner-container-mobile {
    display: flex;
    flex-direction: column;
    height: 800px;
  }
  .banner-image {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: inherit;
    position: relative;
    background: url("./../../Assets/image/deliverEarn/BannerImage.gif")
      center/cover;
  }
  .banner-center-image {
    display: none;
  }
  .heading-warper {
    /* margin-left: 0; */
    padding: 8px;
  }
  .banner-container {
    display: none;
  }
  .spotus-cards{
    padding: 1rem;
  }
  .right-side {
    flex: auto;
    margin-left: 0;
    width: auto;
    padding: 3rem 0rem;
    background: #1d2939 !important;
    height: fit-content !important;
  }

  .scoo_clinic_m_card_wrapper {
    grid-template-columns: repeat(1, 1fr) !important;
  }
  .right-side > h1 {
    margin-top: 0rem;
    font-size: 39px;
  }
  .right-side > h2 {
    font-size: 39px;
    line-height: normal;
  }
  .spotus-cards-wrapper {
    grid-template-columns: repeat(1, 1fr) !important;
  }
}
@media only screen and (max-width: 768px) {
  .banner-container-mobile {
    display: flex;
    flex-direction: column;
    height: 800px;
  }

  .banner-center-image {
    display: none;
  }
  .banner-container {
    display: none;
  }

  .right-side {
    flex: auto;
    margin-left: 0;
    width: auto;
    padding: 3rem 0rem;
    background: #1d2939 !important;
    height: inherit;
    margin-top: 0;
    justify-content: flex-start;
  }

  .scoo_clinic_m_card_wrapper {
    grid-template-columns: repeat(1, 1fr) !important;
  }
  .spotus-cards-wrapper {
    grid-template-columns: repeat(2, 1fr) !important;
  }
  .banner-image {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: inherit;
    position: relative;
    background: url("./../../Assets/image/deliverEarn/BannerImage.gif")
      center/cover;
  }
}
@media only screen and (max-width: 980px) {
  .banner-container {
    display: none;
  }
  .scoo_clinic_m_card_wrapper {
    grid-template-columns: repeat(2, 1fr) !important;
    gap: 1rem;
  }
  .spotus-cards-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr) !important;
    gap: 2rem;
  }

  .banner-container-mobile {
    display: flex;
    flex-direction: column;
    height: 800px;
  }
  .banner-image {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: inherit;
    position: relative;
    background: url("./../../Assets/image/deliverEarn/BannerImage.gif")
      center/cover;
  }
  .banner-center-image {
    display: none;
  }

  .banner-container {
    display: none !important;
  }
  .heading-warper {
    display: none;
  }

  .right-side {
    flex: auto;
    margin-left: 0;
    width: auto;
    padding: 3rem 0rem;
    background: #1d2939 !important;
    height: inherit;
    margin-top: 0;
    justify-content: center;
    align-items: center;
  }
}
