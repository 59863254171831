.ContactAddress_Card{
    display: flex;
flex-direction: column;
align-items: flex-start;
padding: 24px 48px;
gap: 48px;
background: #F9FAFB;
border: 1px solid #E4E7EC;
border-radius: 8px;
}

.contactAddres_head{
font-family: 'Cabin-Regular';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 30px;
color: #060705;
}

.contactAddres_time{
font-family: 'Cabin-Regular';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;
color: #6AA308;
}

.contactAddres_para{
font-family: 'Cabin-Medium';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;
/* or 150% */


/* Gray/600 */

color: #475467;
}

.height_title{
    height: auto;
}

.contact_number{
    font-family: "Cabin-Bold";
    margin-top: 5px;
    font-size: 18px;

}

.contact_number2{
    font-family: "Cabin-Bold";
    margin-top: 5px;
    font-size: 18px;
    background-color: var(--green);
    /* width: fit-content; */
    padding:15px 20px 15px 20px;
    color:white;
    border-radius: 35px;
}

@media only screen and (max-width: 600px) {
    .height_title{
        height: auto;
    }
       
   }