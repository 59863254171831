

.SubBannerbg{
  background: #EDF7DA;
}

.subbanner-title {
  color: #6AA308;
  font-family: "Cabin-Medium";
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.subbanner-sub-title {
  font-family: "RedHatDisplay-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #101828;
}

.subbanner-para {
  font-family: "Cabin-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #475467;
  padding-right: 42px;
}

.center_Title{
  display: flex;
  justify-content: center;
  align-items: center;
}

.SubBanner_Img {
 
  width: 230px;
 
}

.ninija_wrapper{
  display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}



@media only screen and (min-width: 1200px) {
  .SubBannerContainer {
    padding-left: 120px;
    padding-right: 120px;
  }
}
@media screen and (max-width: 600px) {
  .SubBanner_Img,
  .SubBanner_Info {
    width: 100%;
    padding: 10px;
  }
  .subbanner-sub-title{
    font-size: 20px;
    line-height: 32px;
  }

  .subbanner-title{
    margin-top: 20px;
  }
}



@media only screen and (min-width: 600px) and (max-width: 768px) {
  .subbanner-title{
   padding-top: 30px;
  }
}
