.TestimonialContainer {
  padding: 80px 10px;
}

.ninjatestominal_bg{
  background-color: #EDF7DA;
}

.scoo-ninja-testimonial-title {
  font-family: "RedHatDisplay-Regular";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #101828;
}

.scoo-ninja-testimonial-content-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  background: #edf7da;
   border-radius: 24px;
}

.scoo-ninja-testimonial-content-para {
  font-family: "RedHatDisplay-Regular";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 34px;
  color: #101828;
}

.scoo-ninja-testimonial-content-user {
  font-family: "Cabin-regular";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #475467;
}

.scoo-ninja-testimonial-img {
  border-bottom-right-radius: 24px;
  border-top-right-radius: 24px;
}


.Testimonial_Img {
  width: 40%;
}
.Testimonial_Info {
  width: 60%;
  padding: 15px 35px;
}

@media screen and (max-width: 600px) {
  .Testimonial_Img,
  .Testimonial_Info {
    width: 100%;
  }
  .scoo-ninja-testimonial-title{
    font-size: 18px;
  }
  .scoo-ninja-testimonial-content-para{
    font-size: 16px;
    line-height: 26px;
  }
  .scoo-ninja-testimonial-content-user{
    font-size: 14px;
  }
}
@media only screen and (min-width: 1200px) {
  .TestimonialContainer {
    padding-left: 120px;
    padding-right: 120px;
  }
}