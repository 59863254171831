.bg_container{
    background-image:url("../../Assets/image/scoowork/bg.png");
   background-position: center;
   background-size: cover;
   background-repeat: no-repeat;
}

.header{
    color:var(--white);
    font-family: "RedHatDisplay-SemiBold";
  font-style: normal;
  font-size: 30px;
line-height: 40px;
}

.para{
font-family: 'Cabin-Regular';
font-style: normal;
font-weight: 400;
font-size: 1rem;
line-height: 28px;
color:var(--white);
}

.boxShadow_Wrapper{
    background: rgba(255, 255, 255, 0.2);
/* Background blur/md */

backdrop-filter: blur(8px);
/* Note: backdrop-filter has minimal browser support */

border-radius: 8px;
}

.icon_wrpper{
    background-color: var(--green);
    border-radius: 50%;
    width: 43px;
    display: flex;
    justify-content: center;
    /* height: 36px; */
    padding: 10px 10px;
}

.line_wrpper{
    padding: 8px 8px;
}

.work_title{
    color:var(--white);
    font-family: 'Cabin-Regular';
    font-style: normal;
font-weight: 400;
font-size: 1.2rem;
line-height: 30px;
}