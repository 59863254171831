.enroll-now-container {
  background: #84cc0a;
}
.enroll-title {
  font-family: "RedHatDisplay-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
}
.enroll-para {
  font-family: "Cabin-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #ffffff;
}
.enroll-btn {
  width: 120px;
  color: #000;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-weight: 500;
}

.EnrollNow_Img {
  width: 50%;
  background: #fafff1;
  height: 297px;
}
.EnrollNow_Img img {
  object-fit: cover;
}
.EnrollNow_Info {
  width: 50%;
  padding: 25px;
}

@media screen and (max-width: 600px) {
  .EnrollNow_Img,
  .EnrollNow_Info {
    width: 100%;
    height: auto;
  }

  .enrollText_Title{
    padding:20px;
  }

  /* .enroll-now-container{
    padding:20px;
  } */

  /* .enquire_btn{
    margin-bottom: 10px;
  } */
}


@media only screen and (min-width: 600px) and (max-width: 768px) {
 
 }

