.footer_bg{
    background-color: #1D2939;
}

.roket_img{
    width:18px;
}

.scoo_img{
    width: 276px;
    
    padding: 21px;
}

.contactUs_btn{
    padding: 11px 24px;
}

.footer_wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.footer_heder{
    font-family: "RedHatDisplay-SemiBold";
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 40px;
    text-align: center;
    
    /* White */
    
    color: #FFFFFF;
}

.footer_para{
font-family: 'Cabin-Regular';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 30px;
margin-left: 8px;

color: #E4E7EC;



}
/* 
.footer_revo{
    display: flex;
    justify-content: end;
    align-items: end;
} */
.footer_link{
    padding:0px;
   
}

.footer_link li{
   margin-right: 18px;
}

.footer_link li .footer_list{
font-family: 'Cabin-Regular';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 28px;
margin-left: 11px;
color: #E4E7EC;
}

.copyright{
font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;
margin-left: 10px;

color: #98A2B3;
}

.brand_links li{
    margin-right: 15px;
}

.brand_links li img{
   width:20px;
}

.bottom_line{
    color: #e2e7ec;
}

@media only screen and (max-width: 600px) {
    .footer_link {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
    }
    .footer_para{
        /* text-align: center; */
    }
    .scoo_img {
      
        margin: auto;
    }

    .footer_revo {
       margin-top: 10px;
        justify-content: center !important;
        align-items: center;
        margin-bottom: 10px;
        display: flex;
    }

    .thank_header{
        font-size: 1.3rem;
    }
     
  }