.plan_Card {
  background: #ffffff;
  border: 1px solid #e4e7ec;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 30px;

  /* width: 300px;
  height: 305px; */

  cursor: pointer;
}

.plan_head{
 
  color: #101828;
    font-family: "RedHatDisplay-Bold";
    font-style: normal;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 36px;
}

.planbefore_line{
  padding-top: 35px;
}

.plan_Card:hover {
  background-color: #fafff1;
}

.lux_line {
  background-color: #d6ab33;
  color: var(--normal);
}

.plan_container {
  background: #f2f4f7;
}

.ouarterly_Title {
  font-family: "Cabin-Medium";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #475467;
}

.product_rows {
  width: 100%;
}

.quarterly_btn {
  background-color: var(--lightpinkhover) !important;
  width: 100%;
  background: #f9f2e0;
  border: 1px solid #f2e5c0 !important;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.plan_Card:hover {
  border: 1px solid var(--green);
}

.amount_Title {
  font-family: "RedHatDisplay-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 50px;
}
.month_title {
  font-family: "Cabin-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1px;
  text-align: center;
  color: #475467;
}

.rent_btns {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.rent_btns .rent_btn {
  width: 100%;
}

.populare_card {
  position: relative;
}

.popular_btn {
  position: absolute;
  top: -8%;
  right: 20%;
  z-index: 1;
  background-color: var(--black);
  font-family: "Cabin-Regular";
  border-radius: 999px;
  padding: 15px 55px;
  color: var(--white);
}

.luxe_wrapper {
  position: relative;
}

.scoo_btn {
  position: absolute;
    top: -9%;
    right: 20%;
    left:20%;
    z-index: 1;
    background-color: var(--white);
    border: 1px solid #d6ab33;
    border-radius: 999px;
    padding: 0px 44px;
}

.ourtery_Wrapper{
  padding: 30px 4px;
}


.scoo_btn img {
  /* height: 100%; */
  height: 51px;
  object-fit: contain;
}

@media only screen and (max-width: 600px) {
  .header_explore {
    padding: 20px;
  }
  .plan_Card {
    margin-bottom: 30px;
    width: 100%;
  }

  .popular_btn {
    top: -6%;
    right:25%;
  }

  .scoo_btn {
    top: -5%;
    right:25%;
  }

  .planbefore_line {
    padding-top: 0px;
}
}

.link_text{
  color:var(--white);
}

.link_text:hover{
  color:var(--white);
}





@media only screen and (min-width: 600px) and (max-width: 768px) {
  .planbefore_line {
    padding-top: 3px;
}

.plancard_row{
  
    display: flex;
    justify-content: center;
    align-content: center;
}

.plan_Card {
  margin-bottom: 60px;
}
}


@media only screen and (min-width: 768px) and (max-width: 992px) {
  .planbefore_line {
    padding-top: 3px;

}

.plancard_row{
 
    display: flex;
    justify-content: center;
    align-content: center;
}

.plan_Card {
  margin-bottom: 60px;
}




}

