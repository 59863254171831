@font-face {
  font-family: "RedHatDisplay-Black";
  src: url("../src/Assets/Fonts/RedHatFonts/RedHatDisplay-Black.ttf");
}

@font-face {
  font-family: "RedHatDisplay-BlackItalic";
  src: url("../src/Assets/Fonts/RedHatFonts/RedHatDisplay-BlackItalic.ttf");
}

@font-face {
  font-family: "RedHatDisplay-Bold";
  src: url("../src/Assets/Fonts/RedHatFonts/RedHatDisplay-Bold.ttf");
}

@font-face {
  font-family: "RedHatDisplay-BoldItalic";
  src: url("../src/Assets/Fonts/RedHatFonts/RedHatDisplay-BoldItalic.ttf");
}

@font-face {
  font-family: "RedHatDisplay-ExtraBoldItalic";
  src: url("../src/Assets/Fonts/RedHatFonts/RedHatDisplay-ExtraBoldItalic.ttf");
}

@font-face {
  font-family: "RedHatDisplay-Italic";
  src: url("../src/Assets/Fonts/RedHatFonts/RedHatDisplay-Italic.ttf");
}

@font-face {
  font-family: "RedHatDisplay-Light";
  src: url("../src/Assets/Fonts/RedHatFonts/RedHatDisplay-Light.ttf");
}

@font-face {
  font-family: "RedHatDisplay-LightItalic";
  src: url("../src/Assets/Fonts/RedHatFonts/RedHatDisplay-LightItalic.ttf");
}

@font-face {
  font-family: "RedHatDisplay-Medium";
  src: url("../src/Assets/Fonts/RedHatFonts/RedHatDisplay-Medium.ttf");
}

@font-face {
  font-family: "RedHatDisplay-MediumItalic";
  src: url("../src/Assets/Fonts/RedHatFonts/RedHatDisplay-MediumItalic.ttf");
}

@font-face {
  font-family: "RedHatDisplay-Regular";
  src: url("../src/Assets/Fonts/RedHatFonts/RedHatDisplay-Regular.ttf");
}

@font-face {
  font-family: "RedHatDisplay-SemiBold";
  src: url("../src/Assets/Fonts/RedHatFonts/RedHatDisplay-SemiBold.ttf");
}

@font-face {
  font-family: "RedHatDisplay-SemiBoldItalic";
  src: url("../src/Assets/Fonts/RedHatFonts/RedHatDisplay-SemiBoldItalic.ttf");
}

/* cabin fonts */
@font-face {
  font-family: "Cabin-Bold";
  src: url("../src/Assets/Fonts/CabinFonts/Cabin-Bold.ttf");
}

@font-face {
  font-family: "Cabin-BoldItalic";
  src: url("../src/Assets/Fonts/CabinFonts/Cabin-BoldItalic.ttf");
}

@font-face {
  font-family: "Cabin-Italic";
  src: url("../src/Assets/Fonts/CabinFonts/Cabin-Italic.ttf");
}

@font-face {
  font-family: "Cabin-Medium";
  src: url("../src/Assets/Fonts/CabinFonts/Cabin-Medium.ttf");
}

@font-face {
  font-family: "Cabin-MediumItalic";
  src: url("../src/Assets/Fonts/CabinFonts/Cabin-Medium.ttf");
}

@font-face {
  font-family: "Cabin-Regular";
  src: url("../src/Assets/Fonts/CabinFonts/Cabin-Regular.ttf");
}

@font-face {
  font-family: "Cabin-SemiBold";
  src: url("../src/Assets/Fonts/CabinFonts/Cabin-SemiBold.ttf");
}

@font-face {
  font-family: "Cabin-SemiBoldItalic";
  src: url("../src/Assets/Fonts/CabinFonts/Cabin-SemiBoldItalic.ttf");
}

/* inter Fonts */
@font-face {
  font-family: "Inter-Regular";
  src: url("../src/Assets/Fonts/Inter/Inter-Regular.ttf");
}

@font-face {
  font-family: "Inter-Medium";
  src: url("../src/Assets/Fonts/Inter/Inter-Medium.ttf");
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
}

:root {
  --lightpink: #fbf7eb;
  --lightpinkhover: #f9f2e0;
  --lightpinkactive: #f2e5c0;
  --normal: #d6ab33;
  --normalhover: #c19a2e;
  --normalactive: #ab8929;
  --dark: #a18026;
  --darkhover: #80671f;
  --darkactive: #604d17;
  --white: #ffffff;
  --lightwhite: #fafff1;
  --lightwhitehover: #edf7da;
  --lightgreen: #d9efb3;
  --green: #84cc0a;
  --greenhover: #77b809;
  --greenactive: #6aa308;
  --greendark: #639908;
  --greenhover: #639908;
  --greendarker: #2e4704;
  --greendark: #639908;
  --lightblack: #e6e6e6;
  --lighthover: #dadada;
  --lightactive: #b2b2b2;
  --black: #060705;
  --blackhover: #050605;
  --blackactive: #050604;
  --blackdark: #050504;
  --blackdarkactive: #030302;
  --blackdarkhover: #040403;
  --blackdarker: #020202;
  --bt-font-size: 16px;
  --transitons: 0.2s;
}

body {
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
}

html,
body {
  overflow-x: hidden;
}

/* .btn-primary{
  background-color: var(--green);
  color: var(--white);
  border: 1px solid var(--green);
  border-radius: 8px;
  padding: 10px 18px;
} */
.btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check) + .btn:active {
  color: var(--white);
  background-color: var(--greenactive);
  border-color: var(--greenactive);
}
a {
  text-decoration: none;
}

a:hover,
a:focus,
a:active {
  color: var(--greenactive);
  cursor: pointer;
}

button {
  cursor: pointer;
}

button:hover,
button:active,
button:focus {
  border: none;
  outline: none;
}

.header_line {
  color: #101828;
  font-family: "RedHatDisplay-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  padding: 4px px;
}
img {
  width: 100%;
}
.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--greenhover);
  border-color: var(--greenhover);
}

.whatsapp-icon {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 9999;
  cursor: pointer;
}

.whatsapp-icon img {
  width: 50px;
  height: auto;
}

.tankyou_img {
  width: 400px;
  margin-top: 50px;
}

.thankyou_center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.thank_header {
  font-family: "RedHatDisplay-Regular";
  font-style: normal;
  font-weight: 300;
  font-size: 1.5rem;
  line-height: 25px;
  color: #060705;
  text-align: center;
}

.center_title {
  display: flex;
  justify-content: center;
  align-items: center;
}

