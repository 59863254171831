.newletter_bg{
    background: #F9FAFB;
  
}
.newletter_wrapper{
    background: #FFFFFF;
box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
border-radius: 8px;

}

.news_line{
    font-family: 'RedHatDisplay-SemiBold';
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 15px;

/* Gray/900 */

color: #101828;
}

.nesletter_para{
    font-family: 'Cabin-Regular';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 28px;
/* identical to box height, or 156% */


/* Gray/500 */

color: #667085;
}
.subscribe_btns{
    background: #84CC0A;
border: 1px solid #84CC0A;
box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
border-radius: 8px;
font-family: "Cabin-Medium";
}

.subcribe_label{
font-family: 'Cabin-Regular';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 24px;
margin-bottom: 5px;


color: #344054;
}

@media only screen and (max-width: 600px) {
    .news_line {
       
        line-height: 25px;
        
    }
 }