.kyc_Card{
  background: #FAFFF1;
/* border: 1px solid #77B809; */
border-radius: 8px;

}

.kyc_Card_border{

border: 1px solid #77B809;


}

.kyc_Title{
  font-family: "RedHatDisplay-Bold";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
}

.kyc_img{
 height:100%;
  width: 35px;
  object-fit: contain;
}

.question_explore{
  background-color: #84CC0A;
  border: 1px solid #84CC0A;
border-radius: 8px;
}

.phone_wrapper{
  border: 1px solid #FFFFFF;
border-radius: 8px;
padding: 5px 20px;
}

.phone_Title{
  width:25px;
 
  
}
/* .questionRow_wrapper{
  display: flex;
  justify-content: flex-end;
  align-items: end;
} */
.phone_number{
  font-family: "Cabin-Regular";
    font-style: normal;
    font-weight: 400;
    color: #ffff;
    font-size: 1.1rem;
}

.question_title{
  color:#ffff;
  font-family: "RedHatDisplay-SemiBold";
  font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 26px;
 
}


.kyc-container {
  padding: 0 10px;
}
.kyc-title {
  font-family: "RedHatDisplay-Regular";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  color: #101828;
}

.kyc-log-wrap {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 24px;
  gap: 16px;
  width: 245px;
  /* width: 286px; */
  background: #fff;
  border: 1px solid #77b809;
  border-radius: 8px;
  font-family: "RedHatDisplay-Regular";
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  color: #101828;
}
.kyc-adhar {
  background: #fafff1;
}

.kyc-phone {
  padding: 24px 48px;
  background: #84cc0a;
  border: 1px solid #84cc0a;
  border-radius: 8px;
}

.kyc-phone-content {
  font-family: "RedHatDisplay-Regular";
  font-style: normal;
  font-size: 15px;
  line-height: 20px;

  color: #ffffff;
}

.phone-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;
  width: 194px;

  color: #fff;
  border: 1px solid #fff;
  border-radius: 8px;
}

.KYC_Img {
  width: 38px;
  height: 38px;
}

/* @media only screen and (min-width: 1200px) {
  .kyc-container {
    padding-left: 112px;
    padding-right: 112px;
  }
}
@media screen and (max-width: 600px) {
  .kyc-log-wrap {
    width: 100%;
    height: auto;
  }
} */


@media screen and (max-width: 600px) {
.kyc_Card{
  margin-bottom: 15px;
}

.phone_wrapper{
  margin-bottom: 20px;
}

.phone_container{
  margin-bottom: 20px;
}

.phone_wrapper {
  border: 1px solid #FFFFFF;
  border-radius: 8px;
  padding: 10px 55px;
}
}
