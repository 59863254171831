.ScooPageBlogContainer {
  background: #f9fafb;
  padding: 40px 10px;
}
.ScooPageBlogContainerTitle p {
  font-weight: 600;
  font-size: 24px;
  color: #101828;
}
.ScooPageBlogHr {
  width: 1138px;
  height: 0px;
  border: 0.5px solid #d1d5db;
}
@media only screen and (min-width: 1200px) {
  .ScooPageBlogContainer {
    /* padding-left: 116px;
    padding-right: 116px; */
    padding-left: 50px;
    padding-right: 50px;
  }
}
