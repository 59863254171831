.mession_container{
    background-color:#FAFFF1 ;
}

.vision_line{
font-family: 'RedHatDisplay-Bold';
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 36px;
/* identical to box height, or 150% */

display: flex;
align-items: center;

/* ScooEV Rentals/Normal */

color: #84CC0A;
}

.vision_line img{
    width:20px;
}

.mession_title{
    margin-left: 35px;
}

.vision_line{
    position: relative;
}

.vision_line::before{
    content: '';
    display: block;
    position: absolute;
    width: 410px;
    border-bottom: 2px solid #D9EFB3;
    height: 4px;
    border-radius: 1px;
    left: 175px;
}



.mession_container p{
    font-family: 'Cabin-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #475467;
}

@media only screen and (min-width:992px){
    .vision_line::before{
        content: '';
        display: block;
        position: absolute;
        width: 460px;
        border-bottom: 2px solid #D9EFB3;
        height: 4px;
        border-radius: 1px;
        left: 175px;
    }
 }
@media only screen and (min-width: 768px) {
    .vision_line{
        font-size: 1.2rem;
    }
   

    .messionHeader_Wrapper{
        padding-top: 20px;
    }

}
@media only screen and (max-width: 600px) {
    .mession_title{
        margin-left: 5px;
    }

    .vision_line::before {
        
        width: 150px;
       
    }

    .messionHeader_Wrapper{
        padding-top: 20px;
    }
 }

 

