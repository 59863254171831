.scoo-banner-container{
  background: var(--black-normal-hover, #050605);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right 50% top 55%;
}

.prenure_bg {
  background: url("../../../Assets/image/Banner/bg.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.heroBanner_wrapper{
  margin-top: 4rem;
}
.banner_wrapper{

}
.pre_header{
  border-radius: 999px;
  background: rgba(255, 255, 255, 0.20);
  backdrop-filter: blur(8px);
  display: flex;
  padding: 8px 16px;
  align-items: flex-start;
  gap: 8px;
  color: var(--white, #FFF);
  font-family: "Cabin-Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin-right: 90px;
  white-space: nowrap;
}
.pre_header2{
  color: #FFF;
  font-family: "RedHatDisplay-Regular";
  font-size: 46px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.pre_header3{
  color: var(--luxe-normal, #D6AB33);
  font-family: "RedHatDisplay-Bold";
  font-size: 46px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-left: 10px;
}
.pre-para{
  color: var(--gray-100, #F2F4F7);
  font-family: "Cabin-Regular";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; 
}
.pre-wrap{
  padding-top: 8rem;
  padding-bottom: 4rem;
}
/* .scoohero{
  padding-top: 5rem;
} */
.rupee{
    position: absolute;
  right: 0px;
  top: 165px;
  fill: #322912;
  opacity: 0.699999988079071;
  width: 214px;
  height: 315px;
  padding-top: 5rem;
}

@media only screen and (max-width: 600px) {
  .prenure_bg{
    background-image: none;
    background-color: #050605;
  }
  .rupee{
  display: none;
  }

  .pre_header {
   
    white-space: wrap;
}

  .pre-wrap {
    padding-top: 10px;
    padding-bottom: 2px;
}

.pre_header {
  width: 100%;
}

.circle_img {
  width: 100%;
}

.scoohero {
  padding-top: 0rem;
}



.play-btn {
  display: none;
}
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .prenure_bg{
    background-image: none;
    background-color: #050605;
  }
  .rupee{
  display: none;
  }

  .pre_header {
    border-radius: 999px;
    background: rgba(255, 255, 255, 0.20);
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
    display: flex;
    padding: 14px 16px;
    align-items: flex-start;
    gap: 8px;
    color: var(--white, #FFF);
    font-family: "Cabin-Regular";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin-right: 90px;
    white-space: nowrap;
    /* width: 460px; */
}
}