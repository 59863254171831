.LatestCommentsBlogCardContainer {
  background: #f9fafb;
  box-shadow: 0px 20px 25px -5px rgba(16, 24, 40, 0.1),
    0px 8px 10px -6px rgba(16, 24, 40, 0.1);
  border-radius: 4px;
}
.LatestCommentsBlogCardImgBox {
  width: 70px;
  height: 70px;
  object-fit: cover;
  border: none;
  border-radius: 50%;
  margin-top: 8px;
}

.blog_titlteBold{
  font-family: "RedHatDisplay-Bold";
    font-style: normal;
    font-weight: 700;
}
.LatestCommentsBlogCardDes {
  font-family: "Cabin-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #6b7280;
}
.LatestCommentsBlogCardInfoBox {
  width: 260px;
  margin-left: 10px;
}
.LatestCommentsBlogCardInfo p {
  font-family: "Cabin-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #1f2937;
}
.LatestCommentsBlogCardInfo span {
  font-family: "Cabin-Regular";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #6b7280;
}

@media only screen and (max-width: 600px) {
  .LatestCommentsBlogCardInfoBox {
    width: 180px;
  }
}
