.banner_bg{
    background-color: var(--lightwhite);
    
}

.lottiBanner_section{
    padding: 0;
    
    margin: 0;
}
.banner_section{
    background: #F9FAFB;
}
.banner_wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
}
/* .lotti_img{
    position: 'relative';
    width: '100%';
    height: '100%';
  }

  .lotticircle{
    position: 'absolute';
    top: 0;
    left: 0;
    width: 40px;
    height: '100%';
  } */

  .banners_img{
    width: 428px;
height: 510px;

  }

.banner_headbg{
background: #FFFFFF;
border-radius: 999px;
padding: 8px 10px;
font-size: 15px;
color: var(--green);
    font-family: "Cabin-Medium";
    font-weight: 500;
   
    line-height: 24px;

}

.heros_img img{
    margin-left: 50px;
    padding-top: 35px;
}
  


/* .banner_bg .slick-dots li {
    margin-left: 40px;
  
} */


  
 .banner_bg .slick-dots li button:before, .slick-dots li.slick-active button:before {
    color: transparent;
    opacity: 1;
   
  }
 .banner_bg .slick-dots li {
  margin-left: 30px;;
  }
.banner_bg   .slick-dots li button:before {
    margin-top: 5px;
    background-color: transparent;
    border: 1px solid #D9EFB3;
    border-radius: 20%;
    display: inline-block;
    height: 5px;
    width: 50px;
    background-color:#D9EFB3;
  }
.banner_bg   .slick-dots li.slick-active button:before {
    background-color: #6AA308;
    border:1px solid #6AA308;
  }



.banner_header{
font-family: "RedHatDisplay-Regular";
font-style: normal;
font-weight: 300;
font-size: 48px;
line-height: 56px;
color: #060705;
}

.craft_banner{
    color:#84CC0A;
    font-family: "RedHatDisplay-Bold";
    font-weight: 700;
    font-size: 48px;
line-height: 56px;
}



.banner_para{
font-family: 'Cabin-Regular';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 30px;
color: #475467;
}
.enquire_btn{
 background: #FFFFFF;
border: 1px solid #D0D5DD!important;
box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05)!important;
border-radius: 8px;
padding: 10px 18px;
}

.enquire_btn:hover{
    background:#edf7da!important;
   border: 1px solid #D0D5DD!important;
   box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05)!important;
   border-radius: 8px;
   padding: 10px 18px;
   
   }

.enquire_text{
    color: #344054;
    font-family: "Cabin-Medium";

}

/* .heros_img{
    padding:50px;
} */

@media only screen and (max-width: 600px) {
   .heros_img{
    margin-top: 20px;
   }
    .home_banner{
        margin-top: 90px;
       
    }
    .banner_wrapper{
        padding-top:25px;
    }

    .banner_bg .slick-dots{
    margin-left: -55px;
   }

   .banner_header{
    font-size: 40px;
   }

   .craft_banner{
    font-size: 45px;
   }

   .contactHead_title{
    margin-top: 80px;
   }

   .banner_headbg{
    text-align: center;
    font-size: 16px;
   }

   .heros_img img {
     margin-left: 2px; 
    padding-top: 1px; 
}

.banners_img{
    width: 100%;
height: 100%;

  }

.banner_bg .slick-slider {
    border: 1px solid var(--lightwhite);
}

.lottiBanner_section{
    background-color: #FAFFF1;
}

.rentalsBanner_top{
    margin-top: 60px;
}
}


@media only screen and (min-width: 768px) {
    .banner_bg{
        margin-top: 125px;
    }

    .home_banner{
        margin-top: 100px;
       
    }

    .banner_headbg {
        text-align: center;
        font-size: 1.3rem;
    }

}

@media only screen and (min-width: 992px) {
    /* .home_banner{
        border:1px solid rgb(30, 31, 30);
    } */
    .banner_bg{
        margin-top: 1px;
    }

    
.banner_headbg{
    background: #FFFFFF;
    border-radius: 999px;
    padding: 8px 10px;
    font-size: 15px;
    color: var(--green);
        font-family: "Cabin-Medium";
        font-weight: 500;
       
        line-height: 24px;
    
    }
}

/* @media only screen and (min-width: 1200px) {
    .home_banner{
        border:1px solid rgb(242, 255, 98);
    }
} */