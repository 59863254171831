.rentalsBannter_bg{
    background-color: var(--greendark);
}
.landdding_banner{
     background: url("../../../Assets/image/Rentals/greenbg.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.rentalsBannter_bg{
    /* background: url("../../../Assets/image/Rentals/bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; */
    background-color: #D9EFB3;
    border: 1px solid #D9EFB3;
    
  }

  .green_head{
    background-color: rgba(255, 255, 255, 0.2);
    color:var(--white);
  }

  .scoo_white{
    color:var(--white);
  }
.dwn_Card{
    background-color: var(--white);
    border-radius: 0px 0px 10px 10px;
    margin-top: -5px;
}
.year_wrapper{
    display: flex;
    justify-content: space-between;
    align-items:center;
}

.rentalCard_section{
    background: #EDF7DA;
  
    border-radius: 20px;
  

  
   /* -webkit-border-radius: 20px;
linear-gradient(294.75deg, rgba(132, 204, 10, 0.512) 8.01%, rgba(106, 163, 8, 0) 114.97%);
    border-width: 2px; */
    /* border-style: solid; */
    /* -moz-border-image: -moz-linear-gradient(top, #111111 0%, #68c062 100%);
    -webkit-border-image: -webkit-linear-gradient(top, #68c062 0%, #0e0e0e 100%);
    border-image: linear-gradient(to bottom, #f0e2d0 0%, #84CC0A
    100%);
    -webkit-border-image-slice: 2;
    border-image-slice: 2; 
    -webkit-radius-image-slice: 10;
   */

}









.link_month{
    color: #101828;
}

.link_month:hover{
    color: #101828;
}

.dwn_Card li{
    font-family: 'RedHatDisplay-Bold';
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 36px;
    color: #101828!important;
    border-radius: 0px 0px 10px 10px;
}
.rentalsBannter_bg .slick-next {
    right: -1px;
}
.NYX_title{
font-family: 'RedHatDisplay-Bold';
font-style: normal;
font-weight: 700;
font-size: 1.4rem;
line-height: 36px;
color:  #1D2939;

margin-left: 15px;

}

/* .border_title{
    border-right: 1px solid #6AA308;
} */

.rentalsBannter.slick-prev:before, .slick-next:before {
    font-family: 'slick';
    font-size: 53px;
    line-height: 1;
    opacity: .75;
    color: white;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    border:1px solid red;
    display: none;
   
}

.rentalsBannter_bg .slick-prev::before{
    display: none;
}

.nyx_Card{
background: rgba(250, 255, 241, 0.2);
border: 1px solid #D9EFB3;
backdrop-filter: blur(12px);
border-radius: 8px;
}

.nyx_Card img{
    width:20px;
}

.nyx_line{
    color:var(--greenactive);
    border:1px solid #D0D5DD;
}

.nyx_list{
    padding: 15px;
    margin: 0;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
}

.nyx_list li{
    font-family: 'Cabin-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #E4E7EC;
   
}

.speed_Title{
font-family: 'Cabin-Regular';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;
color: #475467;
}

.km_title{
font-family: 'Cabin-Medium';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;
color: #1D2939;
}
.range_col{
    padding: 0px 8px;
}

.icon{
    width:20px;
}

.rentnow_btn{
background: #84CC0A;
border: 1px solid #84CC0A;
box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
border-radius: 8px;
/* display: flex;
justify-content: center;
align-items: center;
flex-direction: column; */
margin: auto;
    text-align: center;
    padding: 10px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 10px 10px 10px 10px;
}

.rent_now{
font-family: "Cabin-Medium";
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;
color: #FFFFFF;
}

/* .rentalsBannter_bg .slick-next:before {
    content: url('../../../Assets//image/Rentals/arrow.png');
    margin-top: 30px;
    
}

.rentalsBannter_bg .slick-prev::before{
    content: url('../../../Assets//image/Rentals/arrow.png');
  
} */
/* 
.rentalsBannter_bg .slick-prev {
        
     
    left: 40px;
} */

.icon_up{
    width:20px;
}

.rentalsBannter_bg .slick-next {
    right: 900px;
}

.nyxLEft_padding{
    padding: 0px 43px;
}

.padding_bottom{
    padding-bottom: 40px;
}

.racer_wrraper{
    display: flex;
    justify-content: center;
    align-items: center;
}

.rentalimg_banner{
    width: auto;
}

.slider_wrapper{
    position: relative;
}

.Next_ARROW{
    position: absolute;
    z-index: 1;
    top:50%;
    left:5%;
   
  
}

.prve_ARROW{
    position: absolute;
    left: 85%;
    top:50%;
}


@media only screen and (max-width: 600px) {
    .nyx_Card{
        margin-bottom: 20px;
    }
   .scoo_white2{
    font-size: 30px;
   }

   .green_scooty_col{
    padding:20px;
   }
    .green_head{
        margin-top: 30px;
        
    }

    .banner_header {
       
        line-height: 40px;
      
    }

    .rentalsBannter_bg .slick-next {
        right: 20px;
    }

    .rentalsBannter_bg .slick-prev {
        left: 0px;
    }

    .craft_banner {
        font-size: 39px;
    }

    

    .rentalimg_banner{
        padding:2px;
      
        width:260px;
    }

    .NYX_title{
        margin-left: 10px;
    }

    .line_nyxnone{
        display: none;
    }

    .prve_ARROW {
        position: absolute;
        left: 81%;
        top: 50%;
    }

    .rentalsBannter_bg{
        padding: 30px 10px;
    }

    .storyCard_section{
        margin-bottom: 20px;
    }


    
     
 }


 @media only screen and (min-width: 600px) and (max-width: 768px) {

 .banner_wrapper{
        padding: 40px;
        
    } 
    .green_head{
        font-size: 14px;
       
        width: 297px;
        
    }

    .green_para {
        font-family: "RedHatDisplay-Regular";
        font-style: normal;
        font-weight: 300;
        font-size: 28px;
        line-height: 35px;
        color: #FFFFFF;
    }

    .craft_banner {
        color: #84CC0A;
        font-family: "RedHatDisplay-Bold";
        font-weight: 700;
        font-size: 31px;
        line-height: 41px;
    }

    .green_scooty_col{
        margin-top: 150px;
    }

    .padding_bottom{
        margin-top: 30px;
    }

    .acces_title{
        font-size: 20px;
    }

    .access_header{
        font-size: 20px;
    }

    .access_para{
        font-size: 20px;
    }

    .storyCard_section{
        margin-bottom: 30px;
    }

 }

